$colorNames: primary, accent, success, review, warn;

// Text style
$body-font-size: 0.85rem;
$small-font-size: 0.7rem;
$big-font-size: 1rem;
$font-family-heading: 'Raleway', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$icon-font: 'Material Icons';

// Color
$fundmore-grey-bg: var(--theme-grey-bg);
$fundmore-grey-background: var(--theme-grey-background);
$fundmore-app-bg: var(--theme-app-bg);
$fundmore-light-grey-bg: var(--theme-light-grey-bg);
$light-grey: var(--theme-light-grey);
$fundmore-dark: var(--theme-light-dark);
$fundmore-active-bg: var(--theme-active-bg);
$fundmore-content-bg: var(--theme-content-bg);

// Border
$fundmore-grey-border-color: var(--theme-grey-border-color);
$fundmore-grey-border: var(--theme-grey-border);
$border: 1px solid var(--fundmore-primary-500);

// Border-radius
$border-radius-md: 10px;
$border-radius-sm: 5px;
$shadow: 0 1px 10px rgba(170, 170, 170, 0.2);

// breakpoints
$breakpoint-tablet: 768px;
$breakpoint-small: 998px;
$breakpoint-medium: 1182px;

// Transition
$transition: 0.3s;

.disable-css-transitions {
  transition: none !important;
}

:root {
  --banner-height: 52px;
  --header-height: 60px;
}

$header-height: 60px;
$app-sidebar-width: 230px;
$app-sidebar-width-collapsed: 55px;
$detail-sidebar-width: 30%;
