.mdc-list.mat-mdc-list-base {
  --mdc-list-list-item-label-text-line-height: 17px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-supporting-text-line-height: 14px;
  --mdc-list-list-item-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-line-height: 15px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;

  .mdc-list-item {
    &.no-padding {
      padding: 0;
    }
    &.mdc-list-item--disabled {
      cursor: not-allowed !important;
      text-decoration: none;
      pointer-events: none;
    }
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin: 0;
  }

  &.mat-mdc-selection-list {
    min-width: 184px;
    .mdc-list-item {
      height: 48px;
      padding-right: 8px;
    }
  }

  .mat-mdc-list-item {
    .mdc-checkbox {
      --mdc-checkbox-selected-checkmark-color: var(--theme-primary-contrast-500);
    }

    &.mat-accent {
      .mdc-checkbox {
        --mdc-checkbox-selected-checkmark-color: var(--theme-accent-contrast-500);
      }
    }
  }
}
