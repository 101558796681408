@use '../variables' as *;

.fm-banner {
  width: 100%;
  height: var(--banner-height);
  padding: 8px;
  background-color: var(--fundmore-review-500);
  color: var(--fundmore-review-contrast-700);
  font-weight: 900;

  .mat-icon,
  .mat-icon.mat-icon-no-color,
  .mat-mdc-button:not(:disabled) {
    color: var(--fundmore-review-contrast-700);
  }
}
