@use '../variables' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
}
