.field-container {
  height: 52px;

  .highlight-container {
    width: 100%;
  }

  .input {
    width: 50%;
  }
}

.list-right > .field-container > .highlight-container > .input > .mat-mdc-form-field {
  text-align: right;
}

.compact-list {
  .field-container {
    .highlight-container {
      width: calc(25% - 15px);
    }

    .long-label,
    .input {
      padding: 0;
    }

    .input {
      width: 100%;
    }
  }
}
