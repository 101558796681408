.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}
fieldset[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
