.mat-mdc-tooltip {
  white-space: pre-line;

  &.pre-wrap {
    white-space: pre-wrap;
  }

  &.text-left {
    .mdc-tooltip__surface {
      text-align: left;
    }
  }
}

.mdc-tooltip {
  &.no-max-width {
    .mdc-tooltip__surface {
      max-width: none;
    }
  }
}
