$colors: (
  primary: (
    50: #e6f5e6,
    100: #c2e5c1,
    200: #9ad499,
    300: #70c46f,
    400: #4eb74f,
    500: #24aa2c,
    600: #189c23,
    700: #008a17,
    800: #007908,
    900: #005b00,
    A100: null,
    A200: null,
    A400: null,
    A700: null,
    contrast: (
      50: black,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: black,
      A200: white,
      A400: white,
      A700: white,
    ),
  ),
  accent: (
    50: #e7eaf0,
    100: #c2cbdc,
    200: #9caac3,
    300: #7789ab,
    400: #5a709a,
    500: #3c588b,
    600: #355083,
    700: #2d4777,
    800: #273d6a,
    900: #1e2d52,
    A100: null,
    A200: null,
    A400: null,
    A700: null,
    contrast: (
      50: black,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: black,
      A200: white,
      A400: white,
      A700: white,
    ),
  ),
  success: (
    50: #e5fbf7,
    100: #a2f2e1,
    200: #71ebd2,
    300: #33e2be,
    400: #1fd7b1,
    500: #1bbc9b,
    600: #17a185,
    700: #13866f,
    800: #0f6c59,
    900: #0c5143,
    contrast: (
      50: black,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: #d7fff7,
      A200: #71ffe2,
      A400: #17f3c6,
      A700: #18d8b1,
    ),
  ),
  review: (
    50: #ffffff,
    100: #ffe5bd,
    200: #ffcf85,
    300: #ffb23d,
    400: #ffa61f,
    500: #ff9a00,
    600: #e08800,
    700: #c27500,
    800: #a36300,
    900: #855000,
    contrast: (
      50: black,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: #ffffff,
      A200: #ffd799,
      A400: #ffae33,
      A700: #ffa41a,
    ),
  ),
  warn: (
    50: #ffedef,
    100: #0f0f0f,
    200: #ffb6bb,
    300: #ff98a0,
    400: #ff828b,
    500: #ff6c77,
    600: #ff646f,
    700: #ff5964,
    800: #ff4f5a,
    900: #ff3d47,
    contrast: (
      50: black,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: black,
      A200: white,
      A400: white,
      A700: white,
    ),
  ),
  muted: (
    50: #fafafa,
    100: #f5f5f5,
    200: #eeeeee,
    300: #e0e0e0,
    400: #bdbdbd,
    500: #9e9e9e,
    600: #757575,
    700: #616161,
    800: #424242,
    900: #212121,
    contrast: (
      50: black,
      100: black,
      200: black,
      300: black,
      400: black,
      500: black,
      600: white,
      700: white,
      800: white,
      900: white,
    ),
  ),
);

:root {
  // default fundmoreai primary color
  // will be used on the login page or any other page that needs to persist AI theme
  --theme-primary-default: #24aa2c;
  // Color
  --theme-light-grey-bg: #f9f9f9;
  --theme-grey-bg: #eeeeee;
  --theme-light-grey: #f7f6f6;
  --theme-light-max: #ffffff;
  --theme-light-dark: rgba(0, 0, 0, 0.6);
  --theme-light-dark-max: rgba(0, 0, 0, 0.87);
  --theme-grey-background: #aaa;
  --theme-app-bg: #fafafa;
  --theme-active-bg: rgba(0, 0, 0, 0.1);
  --theme-content-bg: #ffffff; // used as a background color for the mat card / sidenav (at least)
  // Border
  --theme-grey-border-color: #e0e0e0;
  //   --theme-grey-border-color2: #efe6e6;
  --theme-grey-border: 1px solid var(--theme-grey-border-color);

  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      @if type-of($value) == map {
        @each $contrastShade, $contrastValue in $value {
          --fundmore-#{$color}-contrast-#{$contrastShade}: var(
            --theme-#{$color}-contrast-#{$contrastShade},
            #{$contrastValue}
          );
        }
      } @else {
        --fundmore-#{$color}-#{$shade}: var(--theme-#{$color}-#{$shade}, #{$value});
      }
    }
  }
}
