@use '../variables' as *;

.button-group {
  display: inline-flex;

  > button {
    &:not(:first-of-type, :last-of-type) {
      border-radius: 0;
    }

    &:not(:last-of-type) {
      border-right: var(--theme-grey-border);
    }

    &:first-of-type:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.mat-mdc-menu-trigger {
      min-width: auto;
      padding: 0 8px;

      .mat-icon {
        color: var(--fundmore-primary-contrast-700);
        margin-right: unset;
        font-size: 24px;
        width: auto;
        height: auto;
      }
    }
  }

  // need a span wrapper over the button in order to place tooltips on disabled buttons
  > span > button {
    border-right: var(--theme-grey-border);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.mat-mdc-raised-button.add-new-button {
  display: flex !important;
  justify-content: center;
  margin: 10px 16px;

  .material-icons {
    margin-bottom: 3px;
  }
}

.mat-mdc-icon-button.mat-icon-button-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;

  .mat-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    margin-left: unset;
  }
}

.mat-mdc-icon-button.mat-icon-button-md {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;

  .mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 22px;
    margin-left: unset;
  }
}

.mat-mdc-icon-button.mat-icon-button-in-tab {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 2px;

  .mat-icon {
    width: 28px;
    height: 28px;
    font-size: 28px;
    line-height: 28px;
    margin-left: unset;
  }
}

.add-button.mat-mdc-icon-button {
  &.active {
    .mat-icon {
      color: var(--fundmore-muted-500);
    }
  }
}

.mdc-button {
  &.mat-mdc-button {
    display: inline-flex;
    align-items: center;
    .mat-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
  }
}
.disable-ripple .mat-mdc-button-persistent-ripple {
  display: none;
}

.dark .mat-mdc-raised-button.mat-primary {
  color: var(--fundmore-primary-contrast-500);
}

.fm-btn-sm {
  &.mdc-button {
    height: 28px;
    padding: 0 9px;
  }
}
