@use '../../variables' as *;

.text-heading {
  font-family: $font-family-heading;
  font-feature-settings: 'lnum' 1;
}

.text-dark {
  color: $fundmore-dark !important;
}

.text-primary {
  color: var(--fundmore-primary-500) !important;
}

.text-accent {
  color: var(--fundmore-accent-500) !important;
}

.text-success {
  color: var(--fundmore-success-500) !important;
}

.text-warn {
  color: var(--fundmore-warn-500) !important;
}

.text-review {
  color: var(--fundmore-review-500) !important;
}

.text-muted {
  color: var(--fundmore-muted-500) !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.font-weight-bold {
  font-weight: 700;
}

.align-middle {
  vertical-align: middle;
}

.cursor-auto {
  cursor: auto !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-big-size {
  font-size: $big-font-size !important;
}
