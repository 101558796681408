@use '../../variables' as *;

.bg-content {
  background-color: $fundmore-content-bg !important;
}

.bg-grey {
  background-color: $fundmore-grey-bg !important;
}

.bg-light-grey {
  background-color: $fundmore-light-grey-bg !important;
}

.bg-muted {
  background-color: var(--fundmore-muted-500) !important;
}

.bg-review {
  background-color: var(--fundmore-review-500) !important;
}

.bg-success {
  background-color: var(--fundmore-success-500) !important;
}

.bg-warn {
  background-color: var(--fundmore-warn-500) !important;
}

.bg-none {
  background: none !important;
}
