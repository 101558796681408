@use '../variables' as *;

.mat-mdc-card.fm-card {
  padding: 0;
  border: var(--theme-grey-border);
  border-radius: 10px;
  box-shadow: unset;
  overflow: hidden;

  .mat-mdc-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    background-color: $fundmore-light-grey-bg;
    border-bottom: $fundmore-grey-border;
    font-family: $font-family-heading;
    padding: 0px 16px;

    .mat-mdc-card-title {
      margin-bottom: 0;
      font-size: $big-font-size;
    }
    .mat-mdc-card-header-text {
      width: 100%;
    }
  }

  .mat-mdc-card-content {
    padding: 16px;
  }
}
