.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.maxw-100 {
  max-width: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100%;
}

.h-0 {
  height: 0 !important;
}

$spacers: (
  0: 0,
  4: 4px,
  5: 5px,
  8: 8px,
  10: 10px,
  16: 16px,
  20: 20px,
  28: 28px,
  32: 32px,
  50: 50px,
  64: 64px,
);

@each $prop, $abbrev in (height: h, width: w) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}
