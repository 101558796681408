@use '../variables' as *;

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $fundmore-grey-bg;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color-mix(in srgb, var(--fundmore-muted-500) 60%, transparent);

  &:hover {
    background: var(--fundmore-muted-500);
  }
}
