.xng-breadcrumb-trail {
  padding: 0 10px;
  background-color: var(--theme-active-bg);
  border-radius: 4px;
  line-height: 24px;
}

.xng-breadcrumb-item .xng-breadcrumb-link:hover {
  text-decoration: none;
}
.xng-breadcrumb-list {
  color: var(--theme-light-dark);
  .xng-breadcrumb-trail {
    color: var(--theme-light-dark);
  }
}
