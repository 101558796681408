@use '../variables' as *;

button:not([disabled]) {
  &:not([color]) {
    mat-icon,
    .mat-icon-no-color {
      color: $fundmore-dark;
    }
  }
}

*:not(button) > {
  .mat-icon-no-color,
  .material-icons {
    color: $fundmore-dark;
  }
}

.mat-mdc-icon-button.mat-icon,
.mat-icon,
.material-icons {
  &.mat-icon-sm {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    font-size: 16px;
    line-height: 16px;
  }

  &.mat-icon-md {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
  }

  &.mat-icon-lg {
    width: 64px;
    height: 64px;
    font-size: 64px;
    line-height: 64px;
  }
}
