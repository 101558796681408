@use '../variables' as *;

fm-chat,
fm-ava {
  .mark {
    border-radius: 5px;
    word-break: normal;
    background-color: color-mix(in srgb, var(--fundmore-muted-500) 50%, transparent);
  }

  .reverse-message > div > .message-content > .mark {
    background-color: var(--fundmore-primary-700);
    color: var(--fundmore-primary-contrast-700);
  }
}
