.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
  width: 100%;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}
