@use '../variables' as *;

.basic-button-toggle-group {
  border: none;
  flex-wrap: wrap;

  .mat-button-toggle.basic-button-toggle {
    min-width: 120px;
    border-radius: 4px;
    height: 35px;
    border: $fundmore-grey-border;
    margin: 3px;
    padding: 0 2px;

    // button text
    .mat-button-toggle-label-content {
      line-height: 35px;
    }

    // toggle selected
    &.mat-button-toggle-checked {
      border: 1px solid var(--fundmore-primary-500);
    }
  }
}

.buttons-container {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;

  button {
    white-space: normal;
    line-height: normal;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
