.overflow-auto {
  overflow: auto;
}

// provides a scrollable container when used inside a display flex parent element
.overflow-auto-flex {
  height: 0px;
  min-height: 0px;
  flex: 1 1 0px;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}
