$spacers: (
  0: 0,
  2: 2px,
  4: 4px,
  5: 5px,
  8: 8px,
  10: 10px,
  16: 16px,
  20: 20px,
  28: 28px,
  32: 32px,
  50: 50px,
  64: 64px,
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
