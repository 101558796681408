@use '../../variables' as *;

.border {
  border: var(--theme-grey-border);
}

.border-primary {
  border-color: var(--fundmore-primary-500) !important;
}

.border-top {
  border-top: var(--theme-grey-border) !important;
}

.border-right {
  border-right: var(--theme-grey-border);
}

.border-bottom {
  border-bottom: var(--theme-grey-border);
}

.border-bottom-accent {
  border-bottom-color: var(--fundmore-accent-500) !important;
}

.border-left {
  border-left: var(--theme-grey-border);
}

.border-rounded {
  border-radius: $border-radius-md;
}

.border-rounded-sm {
  border-radius: $border-radius-sm;
}

.border-rounded-0 {
  border-radius: 0 !important;
}

.border-0 {
  border: 0;
}
