@use '@angular/material' as mat;
@use '../variables' as *;
@use 'typography' as *;
@use 'theme-variables' as *;
@use 'sass:map';

.dark {
  $dark-primary: mat.define-palette(map.get($colors, primary));
  $dark-accent: mat.define-palette(map.get($colors, accent));
  $dark-warn: mat.define-palette(map.get($colors, warn));

  $dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $dark-primary,
        accent: $dark-accent,
        warn: $dark-warn,
      ),
      typography: $custom-typography,
      density: 0,
    )
  );

  @include mat.all-component-colors($dark-theme);
  @include mat.core();

  // Color
  --theme-light-grey-bg: #303030;
  --theme-grey-bg: #505050;
  --theme-light-grey: #2e2e2e;
  --theme-light-max: #272727;
  --theme-light-dark: #cccccc;
  --theme-light-dark-max: #fff;
  --theme-app-bg: #303030;
  --theme-active-bg: rgba(0, 0, 0, 0.3);
  --theme-content-bg: #424242;
  // Border
  --theme-grey-border-color: #9e9d9d;
  --theme-grey-border: 1px solid #797979;

  .mat-badge-review {
    .mat-badge-content {
      color: var(--fundmore-review-contrast-700);
      background: var(--fundmore-review-500);
    }
  }

  .mat-badge-accent {
    .mat-badge-content {
      color: var(--fundmore-accent-contrast-700);
      background: var(--fundmore-accent-500);
    }
  }

  .mat-badge-warn {
    .mat-badge-content {
      color: var(--fundmore-warn-contrast-700);
      background: var(--fundmore-warn-500);
    }
  }

  .mat-badge-success {
    .mat-badge-content {
      color: var(--fundmore-success-contrast-700);
      background: var(--fundmore-success-500);
    }
  }
}
