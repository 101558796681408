@use '../variables' as *;

@each $color-name in $colorNames {
  .mat-badge-#{$color-name} {
    .mat-badge-content {
      color: var(--fundmore-#{$color-name}-contrast-700);
      background: var(--fundmore-#{$color-name}-500);
    }
  }
}
