@use '../variables' as *;

.mat-drawer-container {
  background-color: transparent;
  position: unset !important;
}

.sidenav-details {
  width: $detail-sidebar-width;
}

.sidenav-content-scrollable-container {
  height: calc(100vh - var(--application-top-margin));
  padding-bottom: 70px;
  overflow: auto;

  &.sidenav-open {
    margin-right: calc($detail-sidebar-width + $app-sidebar-width * ($detail-sidebar-width / 100%));

    .sidenav-collapsed & {
      margin-right: calc(
        $detail-sidebar-width + $app-sidebar-width-collapsed * ($detail-sidebar-width / 100%)
      );
    }

    > .sidenav-content-scrollable-inner-container {
      min-width: calc(100vw - $app-sidebar-width);

      .sidenav-collapsed & {
        min-width: calc(100vw - $app-sidebar-width-collapsed);
      }
    }
  }
}
