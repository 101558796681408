@use '../variables' as *;

.badge-outline {
  display: inline-block;
  min-width: 40px;
  padding: 1px 7px 2px;
  border: 1px solid;
  border-radius: 30px;
  text-align: center;
  font-size: $body-font-size;
}

@each $color-name in $colorNames {
  .badge-outline-#{$color-name} {
    color: var(--fundmore-#{$color-name}-500);
    border-color: var(--fundmore-#{$color-name}-500);
  }
}
