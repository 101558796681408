// make a mat-menu-item span on multiple lines
.mat-mdc-menu-item.mat-menu-item-multiline {
  display: flex;
  height: auto;
  min-height: 64px;
  align-items: center;
  text-overflow: initial;
  white-space: normal;
  line-height: 24px;

  .mdc-list-item__primary-text {
    display: flex;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.no-max-width {
  max-width: unset;
  max-height: 450px;

  .popover-button-group {
    position: sticky;
    bottom: 0;
    background-color: var(--theme-content-bg);
    padding: 8px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.min-content-width {
  max-width: min-content;
}
