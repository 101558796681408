mat-checkbox.mat-mdc-checkbox {
  padding: 4px;

  --mdc-checkbox-state-layer-size: 20px;
  &.mat-primary {
    --mdc-checkbox-selected-checkmark-color: var(--theme-primary-contrast-500);
  }

  &.mat-accent {
    --mdc-checkbox-selected-checkmark-color: var(--theme-accent-contrast-500);
  }

  &.mat-warn {
    --mdc-checkbox-selected-checkmark-color: var(--theme-warn-contrast-500);
  }
}

// Overwrite the mat-checkbox checked styles.
// Having a mat-checkbox in a disabled fieldset would not display the primary colored background
// of the checkbox because the mat selectors use the :enabled pseudo-class.
// This selectors are a copy of the ones in mat but without :enabled.
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']
  ~ .mdc-checkbox__background {
  border-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786));
  background-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786));
}

.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--fundmore-primary-contrast-500);
}
