.disable-css-transitions {
  transition: none !important;
}

:root {
  --banner-height: 52px;
  --header-height: 60px;
}

:root {
  --theme-primary-default: #24aa2c;
  --theme-light-grey-bg: #f9f9f9;
  --theme-grey-bg: #eeeeee;
  --theme-light-grey: #f7f6f6;
  --theme-light-max: #ffffff;
  --theme-light-dark: rgba(0, 0, 0, 0.6);
  --theme-light-dark-max: rgba(0, 0, 0, 0.87);
  --theme-grey-background: #aaa;
  --theme-app-bg: #fafafa;
  --theme-active-bg: rgba(0, 0, 0, 0.1);
  --theme-content-bg: #ffffff;
  --theme-grey-border-color: #e0e0e0;
  --theme-grey-border: 1px solid var(--theme-grey-border-color);
  --fundmore-primary-50: var(--theme-primary-50, #e6f5e6);
  --fundmore-primary-100: var(--theme-primary-100, #c2e5c1);
  --fundmore-primary-200: var(--theme-primary-200, #9ad499);
  --fundmore-primary-300: var(--theme-primary-300, #70c46f);
  --fundmore-primary-400: var(--theme-primary-400, #4eb74f);
  --fundmore-primary-500: var(--theme-primary-500, #24aa2c);
  --fundmore-primary-600: var(--theme-primary-600, #189c23);
  --fundmore-primary-700: var(--theme-primary-700, #008a17);
  --fundmore-primary-800: var(--theme-primary-800, #007908);
  --fundmore-primary-900: var(--theme-primary-900, #005b00);
  --fundmore-primary-A100: var(--theme-primary-A100, );
  --fundmore-primary-A200: var(--theme-primary-A200, );
  --fundmore-primary-A400: var(--theme-primary-A400, );
  --fundmore-primary-A700: var(--theme-primary-A700, );
  --fundmore-primary-contrast-50: var(
    --theme-primary-contrast-50,
    black
  );
  --fundmore-primary-contrast-100: var(
    --theme-primary-contrast-100,
    white
  );
  --fundmore-primary-contrast-200: var(
    --theme-primary-contrast-200,
    white
  );
  --fundmore-primary-contrast-300: var(
    --theme-primary-contrast-300,
    white
  );
  --fundmore-primary-contrast-400: var(
    --theme-primary-contrast-400,
    white
  );
  --fundmore-primary-contrast-500: var(
    --theme-primary-contrast-500,
    white
  );
  --fundmore-primary-contrast-600: var(
    --theme-primary-contrast-600,
    white
  );
  --fundmore-primary-contrast-700: var(
    --theme-primary-contrast-700,
    white
  );
  --fundmore-primary-contrast-800: var(
    --theme-primary-contrast-800,
    white
  );
  --fundmore-primary-contrast-900: var(
    --theme-primary-contrast-900,
    white
  );
  --fundmore-primary-contrast-A100: var(
    --theme-primary-contrast-A100,
    black
  );
  --fundmore-primary-contrast-A200: var(
    --theme-primary-contrast-A200,
    white
  );
  --fundmore-primary-contrast-A400: var(
    --theme-primary-contrast-A400,
    white
  );
  --fundmore-primary-contrast-A700: var(
    --theme-primary-contrast-A700,
    white
  );
  --fundmore-accent-50: var(--theme-accent-50, #e7eaf0);
  --fundmore-accent-100: var(--theme-accent-100, #c2cbdc);
  --fundmore-accent-200: var(--theme-accent-200, #9caac3);
  --fundmore-accent-300: var(--theme-accent-300, #7789ab);
  --fundmore-accent-400: var(--theme-accent-400, #5a709a);
  --fundmore-accent-500: var(--theme-accent-500, #3c588b);
  --fundmore-accent-600: var(--theme-accent-600, #355083);
  --fundmore-accent-700: var(--theme-accent-700, #2d4777);
  --fundmore-accent-800: var(--theme-accent-800, #273d6a);
  --fundmore-accent-900: var(--theme-accent-900, #1e2d52);
  --fundmore-accent-A100: var(--theme-accent-A100, );
  --fundmore-accent-A200: var(--theme-accent-A200, );
  --fundmore-accent-A400: var(--theme-accent-A400, );
  --fundmore-accent-A700: var(--theme-accent-A700, );
  --fundmore-accent-contrast-50: var(
    --theme-accent-contrast-50,
    black
  );
  --fundmore-accent-contrast-100: var(
    --theme-accent-contrast-100,
    white
  );
  --fundmore-accent-contrast-200: var(
    --theme-accent-contrast-200,
    white
  );
  --fundmore-accent-contrast-300: var(
    --theme-accent-contrast-300,
    white
  );
  --fundmore-accent-contrast-400: var(
    --theme-accent-contrast-400,
    white
  );
  --fundmore-accent-contrast-500: var(
    --theme-accent-contrast-500,
    white
  );
  --fundmore-accent-contrast-600: var(
    --theme-accent-contrast-600,
    white
  );
  --fundmore-accent-contrast-700: var(
    --theme-accent-contrast-700,
    white
  );
  --fundmore-accent-contrast-800: var(
    --theme-accent-contrast-800,
    white
  );
  --fundmore-accent-contrast-900: var(
    --theme-accent-contrast-900,
    white
  );
  --fundmore-accent-contrast-A100: var(
    --theme-accent-contrast-A100,
    black
  );
  --fundmore-accent-contrast-A200: var(
    --theme-accent-contrast-A200,
    white
  );
  --fundmore-accent-contrast-A400: var(
    --theme-accent-contrast-A400,
    white
  );
  --fundmore-accent-contrast-A700: var(
    --theme-accent-contrast-A700,
    white
  );
  --fundmore-success-50: var(--theme-success-50, #e5fbf7);
  --fundmore-success-100: var(--theme-success-100, #a2f2e1);
  --fundmore-success-200: var(--theme-success-200, #71ebd2);
  --fundmore-success-300: var(--theme-success-300, #33e2be);
  --fundmore-success-400: var(--theme-success-400, #1fd7b1);
  --fundmore-success-500: var(--theme-success-500, #1bbc9b);
  --fundmore-success-600: var(--theme-success-600, #17a185);
  --fundmore-success-700: var(--theme-success-700, #13866f);
  --fundmore-success-800: var(--theme-success-800, #0f6c59);
  --fundmore-success-900: var(--theme-success-900, #0c5143);
  --fundmore-success-contrast-50: var(
    --theme-success-contrast-50,
    black
  );
  --fundmore-success-contrast-100: var(
    --theme-success-contrast-100,
    white
  );
  --fundmore-success-contrast-200: var(
    --theme-success-contrast-200,
    white
  );
  --fundmore-success-contrast-300: var(
    --theme-success-contrast-300,
    white
  );
  --fundmore-success-contrast-400: var(
    --theme-success-contrast-400,
    white
  );
  --fundmore-success-contrast-500: var(
    --theme-success-contrast-500,
    white
  );
  --fundmore-success-contrast-600: var(
    --theme-success-contrast-600,
    white
  );
  --fundmore-success-contrast-700: var(
    --theme-success-contrast-700,
    white
  );
  --fundmore-success-contrast-800: var(
    --theme-success-contrast-800,
    white
  );
  --fundmore-success-contrast-900: var(
    --theme-success-contrast-900,
    white
  );
  --fundmore-success-contrast-A100: var(
    --theme-success-contrast-A100,
    #d7fff7
  );
  --fundmore-success-contrast-A200: var(
    --theme-success-contrast-A200,
    #71ffe2
  );
  --fundmore-success-contrast-A400: var(
    --theme-success-contrast-A400,
    #17f3c6
  );
  --fundmore-success-contrast-A700: var(
    --theme-success-contrast-A700,
    #18d8b1
  );
  --fundmore-review-50: var(--theme-review-50, #ffffff);
  --fundmore-review-100: var(--theme-review-100, #ffe5bd);
  --fundmore-review-200: var(--theme-review-200, #ffcf85);
  --fundmore-review-300: var(--theme-review-300, #ffb23d);
  --fundmore-review-400: var(--theme-review-400, #ffa61f);
  --fundmore-review-500: var(--theme-review-500, #ff9a00);
  --fundmore-review-600: var(--theme-review-600, #e08800);
  --fundmore-review-700: var(--theme-review-700, #c27500);
  --fundmore-review-800: var(--theme-review-800, #a36300);
  --fundmore-review-900: var(--theme-review-900, #855000);
  --fundmore-review-contrast-50: var(
    --theme-review-contrast-50,
    black
  );
  --fundmore-review-contrast-100: var(
    --theme-review-contrast-100,
    white
  );
  --fundmore-review-contrast-200: var(
    --theme-review-contrast-200,
    white
  );
  --fundmore-review-contrast-300: var(
    --theme-review-contrast-300,
    white
  );
  --fundmore-review-contrast-400: var(
    --theme-review-contrast-400,
    white
  );
  --fundmore-review-contrast-500: var(
    --theme-review-contrast-500,
    white
  );
  --fundmore-review-contrast-600: var(
    --theme-review-contrast-600,
    white
  );
  --fundmore-review-contrast-700: var(
    --theme-review-contrast-700,
    white
  );
  --fundmore-review-contrast-800: var(
    --theme-review-contrast-800,
    white
  );
  --fundmore-review-contrast-900: var(
    --theme-review-contrast-900,
    white
  );
  --fundmore-review-contrast-A100: var(
    --theme-review-contrast-A100,
    #ffffff
  );
  --fundmore-review-contrast-A200: var(
    --theme-review-contrast-A200,
    #ffd799
  );
  --fundmore-review-contrast-A400: var(
    --theme-review-contrast-A400,
    #ffae33
  );
  --fundmore-review-contrast-A700: var(
    --theme-review-contrast-A700,
    #ffa41a
  );
  --fundmore-warn-50: var(--theme-warn-50, #ffedef);
  --fundmore-warn-100: var(--theme-warn-100, #0f0f0f);
  --fundmore-warn-200: var(--theme-warn-200, #ffb6bb);
  --fundmore-warn-300: var(--theme-warn-300, #ff98a0);
  --fundmore-warn-400: var(--theme-warn-400, #ff828b);
  --fundmore-warn-500: var(--theme-warn-500, #ff6c77);
  --fundmore-warn-600: var(--theme-warn-600, #ff646f);
  --fundmore-warn-700: var(--theme-warn-700, #ff5964);
  --fundmore-warn-800: var(--theme-warn-800, #ff4f5a);
  --fundmore-warn-900: var(--theme-warn-900, #ff3d47);
  --fundmore-warn-contrast-50: var(
    --theme-warn-contrast-50,
    black
  );
  --fundmore-warn-contrast-100: var(
    --theme-warn-contrast-100,
    white
  );
  --fundmore-warn-contrast-200: var(
    --theme-warn-contrast-200,
    white
  );
  --fundmore-warn-contrast-300: var(
    --theme-warn-contrast-300,
    white
  );
  --fundmore-warn-contrast-400: var(
    --theme-warn-contrast-400,
    white
  );
  --fundmore-warn-contrast-500: var(
    --theme-warn-contrast-500,
    white
  );
  --fundmore-warn-contrast-600: var(
    --theme-warn-contrast-600,
    white
  );
  --fundmore-warn-contrast-700: var(
    --theme-warn-contrast-700,
    white
  );
  --fundmore-warn-contrast-800: var(
    --theme-warn-contrast-800,
    white
  );
  --fundmore-warn-contrast-900: var(
    --theme-warn-contrast-900,
    white
  );
  --fundmore-warn-contrast-A100: var(
    --theme-warn-contrast-A100,
    black
  );
  --fundmore-warn-contrast-A200: var(
    --theme-warn-contrast-A200,
    white
  );
  --fundmore-warn-contrast-A400: var(
    --theme-warn-contrast-A400,
    white
  );
  --fundmore-warn-contrast-A700: var(
    --theme-warn-contrast-A700,
    white
  );
  --fundmore-muted-50: var(--theme-muted-50, #fafafa);
  --fundmore-muted-100: var(--theme-muted-100, #f5f5f5);
  --fundmore-muted-200: var(--theme-muted-200, #eeeeee);
  --fundmore-muted-300: var(--theme-muted-300, #e0e0e0);
  --fundmore-muted-400: var(--theme-muted-400, #bdbdbd);
  --fundmore-muted-500: var(--theme-muted-500, #9e9e9e);
  --fundmore-muted-600: var(--theme-muted-600, #757575);
  --fundmore-muted-700: var(--theme-muted-700, #616161);
  --fundmore-muted-800: var(--theme-muted-800, #424242);
  --fundmore-muted-900: var(--theme-muted-900, #212121);
  --fundmore-muted-contrast-50: var(
    --theme-muted-contrast-50,
    black
  );
  --fundmore-muted-contrast-100: var(
    --theme-muted-contrast-100,
    black
  );
  --fundmore-muted-contrast-200: var(
    --theme-muted-contrast-200,
    black
  );
  --fundmore-muted-contrast-300: var(
    --theme-muted-contrast-300,
    black
  );
  --fundmore-muted-contrast-400: var(
    --theme-muted-contrast-400,
    black
  );
  --fundmore-muted-contrast-500: var(
    --theme-muted-contrast-500,
    black
  );
  --fundmore-muted-contrast-600: var(
    --theme-muted-contrast-600,
    white
  );
  --fundmore-muted-contrast-700: var(
    --theme-muted-contrast-700,
    white
  );
  --fundmore-muted-contrast-800: var(
    --theme-muted-contrast-800,
    white
  );
  --fundmore-muted-contrast-900: var(
    --theme-muted-contrast-900,
    white
  );
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #24aa2c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #3c588b;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #ff6c77;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #24aa2c;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #24aa2c;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3c588b;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3c588b;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3c588b;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3c588b;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff6c77;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff6c77;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-option-label-text-line-height: 19px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-optgroup-label-text-line-height: 19px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Raleway, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-card-title-text-line-height: 22px;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-card-subtitle-text-line-height: 16px;
  --mat-card-subtitle-text-size: 12px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #24aa2c;
  --mdc-linear-progress-track-color: rgba(36, 170, 44, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(36, 170, 44, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(36, 170, 44, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(36, 170, 44, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(36, 170, 44, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(36, 170, 44, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #3c588b;
  --mdc-linear-progress-track-color: rgba(60, 88, 139, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(60, 88, 139, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(60, 88, 139, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(60, 88, 139, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(60, 88, 139, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(60, 88, 139, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff6c77;
  --mdc-linear-progress-track-color: rgba(255, 108, 119, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 108, 119, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 108, 119, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 108, 119, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 108, 119, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 108, 119, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-caret-color: #24aa2c;
  --mdc-filled-text-field-focus-active-indicator-color: #24aa2c;
  --mdc-filled-text-field-focus-label-text-color: rgba(36, 170, 44, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ff6c77;
  --mdc-filled-text-field-error-label-text-color: #ff6c77;
  --mdc-filled-text-field-error-caret-color: #ff6c77;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ff6c77;
  --mdc-outlined-text-field-caret-color: #24aa2c;
  --mdc-outlined-text-field-focus-outline-color: #24aa2c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(36, 170, 44, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ff6c77;
  --mdc-outlined-text-field-error-focus-label-text-color: #ff6c77;
  --mdc-outlined-text-field-error-label-text-color: #ff6c77;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ff6c77;
  --mdc-outlined-text-field-error-hover-outline-color: #ff6c77;
  --mdc-outlined-text-field-error-outline-color: #ff6c77;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff6c77);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(36, 170, 44, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(60, 88, 139, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 108, 119, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #3c588b;
  --mdc-filled-text-field-focus-active-indicator-color: #3c588b;
  --mdc-filled-text-field-focus-label-text-color: rgba(60, 88, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #3c588b;
  --mdc-outlined-text-field-focus-outline-color: #3c588b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(60, 88, 139, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ff6c77;
  --mdc-filled-text-field-focus-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 108, 119, 0.87);
  --mdc-outlined-text-field-caret-color: #ff6c77;
  --mdc-outlined-text-field-focus-outline-color: #ff6c77;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 108, 119, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-form-field-container-text-line-height: 19px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-form-field-subscript-text-line-height: 15px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(36, 170, 44, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(60, 88, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 108, 119, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}

html {
  --mat-select-trigger-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-select-trigger-text-line-height: 19px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Raleway, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-dialog-subhead-line-height: 22px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-dialog-supporting-text-line-height: 19px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #24aa2c;
  --mdc-chip-elevated-disabled-container-color: #24aa2c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3c588b;
  --mdc-chip-elevated-disabled-container-color: #3c588b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff6c77;
  --mdc-chip-elevated-disabled-container-color: #ff6c77;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-chip-label-text-line-height: 19px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 500;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #189c23;
  --mdc-switch-selected-handle-color: #189c23;
  --mdc-switch-selected-hover-state-layer-color: #189c23;
  --mdc-switch-selected-pressed-state-layer-color: #189c23;
  --mdc-switch-selected-focus-handle-color: #005b00;
  --mdc-switch-selected-hover-handle-color: #005b00;
  --mdc-switch-selected-pressed-handle-color: #005b00;
  --mdc-switch-selected-focus-track-color: #70c46f;
  --mdc-switch-selected-hover-track-color: #70c46f;
  --mdc-switch-selected-pressed-track-color: #70c46f;
  --mdc-switch-selected-track-color: #70c46f;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #355083;
  --mdc-switch-selected-handle-color: #355083;
  --mdc-switch-selected-hover-state-layer-color: #355083;
  --mdc-switch-selected-pressed-state-layer-color: #355083;
  --mdc-switch-selected-focus-handle-color: #1e2d52;
  --mdc-switch-selected-hover-handle-color: #1e2d52;
  --mdc-switch-selected-pressed-handle-color: #1e2d52;
  --mdc-switch-selected-focus-track-color: #7789ab;
  --mdc-switch-selected-hover-track-color: #7789ab;
  --mdc-switch-selected-pressed-track-color: #7789ab;
  --mdc-switch-selected-track-color: #7789ab;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #ff646f;
  --mdc-switch-selected-handle-color: #ff646f;
  --mdc-switch-selected-hover-state-layer-color: #ff646f;
  --mdc-switch-selected-pressed-state-layer-color: #ff646f;
  --mdc-switch-selected-focus-handle-color: #ff3d47;
  --mdc-switch-selected-hover-handle-color: #ff3d47;
  --mdc-switch-selected-pressed-handle-color: #ff3d47;
  --mdc-switch-selected-focus-track-color: #ff98a0;
  --mdc-switch-selected-hover-track-color: #ff98a0;
  --mdc-switch-selected-pressed-track-color: #ff98a0;
  --mdc-switch-selected-track-color: #ff98a0;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 19px;
  --mat-slide-toggle-label-text-weight: 500;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #24aa2c;
  --mdc-radio-selected-hover-icon-color: #24aa2c;
  --mdc-radio-selected-icon-color: #24aa2c;
  --mdc-radio-selected-pressed-icon-color: #24aa2c;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #24aa2c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3c588b;
  --mdc-radio-selected-hover-icon-color: #3c588b;
  --mdc-radio-selected-icon-color: #3c588b;
  --mdc-radio-selected-pressed-icon-color: #3c588b;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #3c588b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff6c77;
  --mdc-radio-selected-hover-icon-color: #ff6c77;
  --mdc-radio-selected-icon-color: #ff6c77;
  --mdc-radio-selected-pressed-icon-color: #ff6c77;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff6c77;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 19px);
  font-weight: var(--mdc-typography-body2-font-weight, 500);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #24aa2c;
  --mdc-slider-focus-handle-color: #24aa2c;
  --mdc-slider-hover-handle-color: #24aa2c;
  --mdc-slider-active-track-color: #24aa2c;
  --mdc-slider-inactive-track-color: #24aa2c;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #24aa2c;
  --mat-mdc-slider-ripple-color: #24aa2c;
  --mat-mdc-slider-hover-ripple-color: rgba(36, 170, 44, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(36, 170, 44, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #3c588b;
  --mdc-slider-focus-handle-color: #3c588b;
  --mdc-slider-hover-handle-color: #3c588b;
  --mdc-slider-active-track-color: #3c588b;
  --mdc-slider-inactive-track-color: #3c588b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3c588b;
  --mat-mdc-slider-ripple-color: #3c588b;
  --mat-mdc-slider-hover-ripple-color: rgba(60, 88, 139, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(60, 88, 139, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff6c77;
  --mdc-slider-focus-handle-color: #ff6c77;
  --mdc-slider-hover-handle-color: #ff6c77;
  --mdc-slider-active-track-color: #ff6c77;
  --mdc-slider-inactive-track-color: #ff6c77;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff6c77;
  --mat-mdc-slider-ripple-color: #ff6c77;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 108, 119, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 108, 119, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-slider-label-label-text-size: 12px;
  --mdc-slider-label-label-text-line-height: 16px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 400;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 19px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #24aa2c;
  --mdc-radio-selected-hover-icon-color: #24aa2c;
  --mdc-radio-selected-icon-color: #24aa2c;
  --mdc-radio-selected-pressed-icon-color: #24aa2c;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3c588b;
  --mdc-radio-selected-hover-icon-color: #3c588b;
  --mdc-radio-selected-icon-color: #3c588b;
  --mdc-radio-selected-pressed-icon-color: #3c588b;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff6c77;
  --mdc-radio-selected-hover-icon-color: #ff6c77;
  --mdc-radio-selected-icon-color: #ff6c77;
  --mdc-radio-selected-pressed-icon-color: #ff6c77;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #24aa2c;
  --mdc-checkbox-selected-hover-icon-color: #24aa2c;
  --mdc-checkbox-selected-icon-color: #24aa2c;
  --mdc-checkbox-selected-pressed-icon-color: #24aa2c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-hover-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-pressed-state-layer-color: #24aa2c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3c588b;
  --mdc-checkbox-selected-hover-icon-color: #3c588b;
  --mdc-checkbox-selected-icon-color: #3c588b;
  --mdc-checkbox-selected-pressed-icon-color: #3c588b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3c588b;
  --mdc-checkbox-selected-hover-state-layer-color: #3c588b;
  --mdc-checkbox-selected-pressed-state-layer-color: #3c588b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff6c77;
  --mdc-checkbox-selected-hover-icon-color: #ff6c77;
  --mdc-checkbox-selected-icon-color: #ff6c77;
  --mdc-checkbox-selected-pressed-icon-color: #ff6c77;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-hover-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff6c77;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #24aa2c;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #24aa2c;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-label-text-line-height: 19px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-supporting-text-line-height: 19px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-list-list-item-trailing-supporting-text-line-height: 15px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  font-family: "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-paginator-container-text-line-height: 15px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #24aa2c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #24aa2c;
  --mat-tab-header-active-ripple-color: #24aa2c;
  --mat-tab-header-inactive-ripple-color: #24aa2c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #24aa2c;
  --mat-tab-header-active-hover-label-text-color: #24aa2c;
  --mat-tab-header-active-focus-indicator-color: #24aa2c;
  --mat-tab-header-active-hover-indicator-color: #24aa2c;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #3c588b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3c588b;
  --mat-tab-header-active-ripple-color: #3c588b;
  --mat-tab-header-inactive-ripple-color: #3c588b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3c588b;
  --mat-tab-header-active-hover-label-text-color: #3c588b;
  --mat-tab-header-active-focus-indicator-color: #3c588b;
  --mat-tab-header-active-hover-indicator-color: #3c588b;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ff6c77;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff6c77;
  --mat-tab-header-active-ripple-color: #ff6c77;
  --mat-tab-header-inactive-ripple-color: #ff6c77;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff6c77;
  --mat-tab-header-active-hover-label-text-color: #ff6c77;
  --mat-tab-header-active-focus-indicator-color: #ff6c77;
  --mat-tab-header-active-hover-indicator-color: #ff6c77;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #24aa2c;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #3c588b;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ff6c77;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Raleway, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 19px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3c588b;
  --mdc-checkbox-selected-hover-icon-color: #3c588b;
  --mdc-checkbox-selected-icon-color: #3c588b;
  --mdc-checkbox-selected-pressed-icon-color: #3c588b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3c588b;
  --mdc-checkbox-selected-hover-state-layer-color: #3c588b;
  --mdc-checkbox-selected-pressed-state-layer-color: #3c588b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #24aa2c;
  --mdc-checkbox-selected-hover-icon-color: #24aa2c;
  --mdc-checkbox-selected-icon-color: #24aa2c;
  --mdc-checkbox-selected-pressed-icon-color: #24aa2c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-hover-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-pressed-state-layer-color: #24aa2c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff6c77;
  --mdc-checkbox-selected-hover-icon-color: #ff6c77;
  --mdc-checkbox-selected-icon-color: #ff6c77;
  --mdc-checkbox-selected-pressed-icon-color: #ff6c77;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-hover-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff6c77;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 19px);
  font-weight: var(--mdc-typography-body2-font-weight, 500);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #24aa2c;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3c588b;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff6c77;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #24aa2c;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3c588b;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff6c77;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #24aa2c;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3c588b;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff6c77;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #24aa2c;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3c588b;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff6c77;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #24aa2c;
  --mat-mdc-button-ripple-color: rgba(36, 170, 44, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3c588b;
  --mat-mdc-button-ripple-color: rgba(60, 88, 139, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff6c77;
  --mat-mdc-button-ripple-color: rgba(255, 108, 119, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 19px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #24aa2c;
  --mat-mdc-button-persistent-ripple-color: #24aa2c;
  --mat-mdc-button-ripple-color: rgba(36, 170, 44, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3c588b;
  --mat-mdc-button-persistent-ripple-color: #3c588b;
  --mat-mdc-button-ripple-color: rgba(60, 88, 139, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff6c77;
  --mat-mdc-button-persistent-ripple-color: #ff6c77;
  --mat-mdc-button-ripple-color: rgba(255, 108, 119, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #24aa2c;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #3c588b;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff6c77;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 19px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Raleway, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #3c588b;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mdc-snackbar-supporting-text-line-height: 19px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 500;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-header-headline-line-height: 16px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-row-item-label-text-line-height: 19px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-table-footer-supporting-text-line-height: 19px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #24aa2c;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #3c588b;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff6c77;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #24aa2c;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #3c588b;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #ff6c77;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-bottom-sheet-container-text-line-height: 19px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 500;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-standard-button-toggle-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #24aa2c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(36, 170, 44, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(36, 170, 44, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(36, 170, 44, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #24aa2c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(36, 170, 44, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3c588b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(60, 88, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(60, 88, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(60, 88, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(60, 88, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff6c77;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 108, 119, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 108, 119, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 108, 119, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 108, 119, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #3c588b;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ff6c77;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-expansion-header-text-size: 12px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-expansion-container-text-line-height: 19px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 500;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #24aa2c;
}
.mat-icon.mat-accent {
  --mat-icon-color: #3c588b;
}
.mat-icon.mat-warn {
  --mat-icon-color: #ff6c77;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #24aa2c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #24aa2c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #24aa2c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #ff6c77;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #ff6c77;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3c588b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3c588b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3c588b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff6c77;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff6c77;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff6c77;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-stepper-header-label-text-font: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #24aa2c;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #3c588b;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ff6c77;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Raleway, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --mat-toolbar-title-text-line-height: 22px;
  --mat-toolbar-title-text-size: 18px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: var(--theme-accent-contrast-500);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--theme-accent-contrast-500);
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --theme-light-grey-bg: #303030;
  --theme-grey-bg: #505050;
  --theme-light-grey: #2e2e2e;
  --theme-light-max: #272727;
  --theme-light-dark: #cccccc;
  --theme-light-dark-max: #fff;
  --theme-app-bg: #303030;
  --theme-active-bg: rgba(0, 0, 0, 0.3);
  --theme-content-bg: #424242;
  --theme-grey-border-color: #9e9d9d;
  --theme-grey-border: 1px solid #797979;
}
.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark {
  --mat-option-selected-state-label-text-color: #24aa2c;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #3c588b;
}
.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #ff6c77;
}
.dark {
  --mat-optgroup-label-text-color: white;
}

.dark .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #24aa2c;
}
.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #24aa2c;
}
.dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3c588b;
}
.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3c588b;
}
.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3c588b;
}
.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3c588b;
}
.dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff6c77;
}
.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff6c77;
}
.dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.dark .mat-app-background, .dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark .mat-elevation-z0, .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z1, .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z2, .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z3, .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z4, .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z5, .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z6, .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z7, .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z8, .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z9, .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z10, .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z11, .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z12, .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z13, .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z14, .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z15, .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z16, .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z17, .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z18, .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z19, .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z20, .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z21, .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z22, .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z23, .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark .mat-elevation-z24, .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #24aa2c;
  --mdc-linear-progress-track-color: rgba(36, 170, 44, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(36, 170, 44, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(36, 170, 44, 0.25));
}
@media (forced-colors: active) {
  .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(36, 170, 44, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(36, 170, 44, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(36, 170, 44, 0.25));
}
.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #3c588b;
  --mdc-linear-progress-track-color: rgba(60, 88, 139, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(60, 88, 139, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(60, 88, 139, 0.25));
}
@media (forced-colors: active) {
  .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(60, 88, 139, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(60, 88, 139, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(60, 88, 139, 0.25));
}
.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ff6c77;
  --mdc-linear-progress-track-color: rgba(255, 108, 119, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 108, 119, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 108, 119, 0.25));
}
@media (forced-colors: active) {
  .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 108, 119, 0.25)'/%3E%3C/svg%3E");
  }
}
.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 108, 119, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 108, 119, 0.25));
}
.dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.dark {
  --mdc-filled-text-field-caret-color: #24aa2c;
  --mdc-filled-text-field-focus-active-indicator-color: #24aa2c;
  --mdc-filled-text-field-focus-label-text-color: rgba(36, 170, 44, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ff6c77;
  --mdc-filled-text-field-error-label-text-color: #ff6c77;
  --mdc-filled-text-field-error-caret-color: #ff6c77;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ff6c77;
  --mdc-outlined-text-field-caret-color: #24aa2c;
  --mdc-outlined-text-field-focus-outline-color: #24aa2c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(36, 170, 44, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ff6c77;
  --mdc-outlined-text-field-error-focus-label-text-color: #ff6c77;
  --mdc-outlined-text-field-error-label-text-color: #ff6c77;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ff6c77;
  --mdc-outlined-text-field-error-hover-outline-color: #ff6c77;
  --mdc-outlined-text-field-error-outline-color: #ff6c77;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ff6c77);
}
.dark .mat-mdc-form-field-subscript-wrapper,
.dark .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(36, 170, 44, 0.87);
}
.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(60, 88, 139, 0.87);
}
.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 108, 119, 0.87);
}
.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #3c588b;
  --mdc-filled-text-field-focus-active-indicator-color: #3c588b;
  --mdc-filled-text-field-focus-label-text-color: rgba(60, 88, 139, 0.87);
  --mdc-outlined-text-field-caret-color: #3c588b;
  --mdc-outlined-text-field-focus-outline-color: #3c588b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(60, 88, 139, 0.87);
}
.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ff6c77;
  --mdc-filled-text-field-focus-active-indicator-color: #ff6c77;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 108, 119, 0.87);
  --mdc-outlined-text-field-caret-color: #ff6c77;
  --mdc-outlined-text-field-focus-outline-color: #ff6c77;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 108, 119, 0.87);
}
.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.dark {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(36, 170, 44, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}
.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(60, 88, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}
.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 108, 119, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 108, 119, 0.87);
}

.dark {
  --mat-autocomplete-background-color: #424242;
}

.dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #24aa2c;
  --mdc-chip-elevated-disabled-container-color: #24aa2c;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3c588b;
  --mdc-chip-elevated-disabled-container-color: #3c588b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff6c77;
  --mdc-chip-elevated-disabled-container-color: #ff6c77;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #70c46f;
  --mdc-switch-selected-handle-color: #70c46f;
  --mdc-switch-selected-hover-state-layer-color: #70c46f;
  --mdc-switch-selected-pressed-state-layer-color: #70c46f;
  --mdc-switch-selected-focus-handle-color: #9ad499;
  --mdc-switch-selected-hover-handle-color: #9ad499;
  --mdc-switch-selected-pressed-handle-color: #9ad499;
  --mdc-switch-selected-focus-track-color: #189c23;
  --mdc-switch-selected-hover-track-color: #189c23;
  --mdc-switch-selected-pressed-track-color: #189c23;
  --mdc-switch-selected-track-color: #189c23;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #7789ab;
  --mdc-switch-selected-handle-color: #7789ab;
  --mdc-switch-selected-hover-state-layer-color: #7789ab;
  --mdc-switch-selected-pressed-state-layer-color: #7789ab;
  --mdc-switch-selected-focus-handle-color: #9caac3;
  --mdc-switch-selected-hover-handle-color: #9caac3;
  --mdc-switch-selected-pressed-handle-color: #9caac3;
  --mdc-switch-selected-focus-track-color: #355083;
  --mdc-switch-selected-hover-track-color: #355083;
  --mdc-switch-selected-pressed-track-color: #355083;
  --mdc-switch-selected-track-color: #355083;
}
.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #ff98a0;
  --mdc-switch-selected-handle-color: #ff98a0;
  --mdc-switch-selected-hover-state-layer-color: #ff98a0;
  --mdc-switch-selected-pressed-state-layer-color: #ff98a0;
  --mdc-switch-selected-focus-handle-color: #ffb6bb;
  --mdc-switch-selected-hover-handle-color: #ffb6bb;
  --mdc-switch-selected-pressed-handle-color: #ffb6bb;
  --mdc-switch-selected-focus-track-color: #ff646f;
  --mdc-switch-selected-hover-track-color: #ff646f;
  --mdc-switch-selected-pressed-track-color: #ff646f;
  --mdc-switch-selected-track-color: #ff646f;
}
.dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #24aa2c;
  --mdc-radio-selected-hover-icon-color: #24aa2c;
  --mdc-radio-selected-icon-color: #24aa2c;
  --mdc-radio-selected-pressed-icon-color: #24aa2c;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #24aa2c;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3c588b;
  --mdc-radio-selected-hover-icon-color: #3c588b;
  --mdc-radio-selected-icon-color: #3c588b;
  --mdc-radio-selected-pressed-icon-color: #3c588b;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #3c588b;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff6c77;
  --mdc-radio-selected-hover-icon-color: #ff6c77;
  --mdc-radio-selected-icon-color: #ff6c77;
  --mdc-radio-selected-pressed-icon-color: #ff6c77;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #ff6c77;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #24aa2c;
  --mdc-slider-focus-handle-color: #24aa2c;
  --mdc-slider-hover-handle-color: #24aa2c;
  --mdc-slider-active-track-color: #24aa2c;
  --mdc-slider-inactive-track-color: #24aa2c;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #24aa2c;
  --mat-mdc-slider-ripple-color: #24aa2c;
  --mat-mdc-slider-hover-ripple-color: rgba(36, 170, 44, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(36, 170, 44, 0.2);
}
.dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #3c588b;
  --mdc-slider-focus-handle-color: #3c588b;
  --mdc-slider-hover-handle-color: #3c588b;
  --mdc-slider-active-track-color: #3c588b;
  --mdc-slider-inactive-track-color: #3c588b;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3c588b;
  --mat-mdc-slider-ripple-color: #3c588b;
  --mat-mdc-slider-hover-ripple-color: rgba(60, 88, 139, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(60, 88, 139, 0.2);
}
.dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ff6c77;
  --mdc-slider-focus-handle-color: #ff6c77;
  --mdc-slider-hover-handle-color: #ff6c77;
  --mdc-slider-active-track-color: #ff6c77;
  --mdc-slider-inactive-track-color: #ff6c77;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff6c77;
  --mat-mdc-slider-ripple-color: #ff6c77;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 108, 119, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 108, 119, 0.2);
}
.dark {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
}

.dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.dark .mdc-list-item__start,
.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #24aa2c;
  --mdc-radio-selected-hover-icon-color: #24aa2c;
  --mdc-radio-selected-icon-color: #24aa2c;
  --mdc-radio-selected-pressed-icon-color: #24aa2c;
}
.dark .mat-accent .mdc-list-item__start,
.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #3c588b;
  --mdc-radio-selected-hover-icon-color: #3c588b;
  --mdc-radio-selected-icon-color: #3c588b;
  --mdc-radio-selected-pressed-icon-color: #3c588b;
}
.dark .mat-warn .mdc-list-item__start,
.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff6c77;
  --mdc-radio-selected-hover-icon-color: #ff6c77;
  --mdc-radio-selected-icon-color: #ff6c77;
  --mdc-radio-selected-pressed-icon-color: #ff6c77;
}
.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #24aa2c;
  --mdc-checkbox-selected-hover-icon-color: #24aa2c;
  --mdc-checkbox-selected-icon-color: #24aa2c;
  --mdc-checkbox-selected-pressed-icon-color: #24aa2c;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-hover-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-pressed-state-layer-color: #24aa2c;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3c588b;
  --mdc-checkbox-selected-hover-icon-color: #3c588b;
  --mdc-checkbox-selected-icon-color: #3c588b;
  --mdc-checkbox-selected-pressed-icon-color: #3c588b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3c588b;
  --mdc-checkbox-selected-hover-state-layer-color: #3c588b;
  --mdc-checkbox-selected-pressed-state-layer-color: #3c588b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff6c77;
  --mdc-checkbox-selected-hover-icon-color: #ff6c77;
  --mdc-checkbox-selected-icon-color: #ff6c77;
  --mdc-checkbox-selected-pressed-icon-color: #ff6c77;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-hover-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff6c77;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #24aa2c;
}
.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #24aa2c;
}
.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.dark .mat-mdc-tab-group, .dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #24aa2c;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #24aa2c;
  --mat-tab-header-active-ripple-color: #24aa2c;
  --mat-tab-header-inactive-ripple-color: #24aa2c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #24aa2c;
  --mat-tab-header-active-hover-label-text-color: #24aa2c;
  --mat-tab-header-active-focus-indicator-color: #24aa2c;
  --mat-tab-header-active-hover-indicator-color: #24aa2c;
}
.dark .mat-mdc-tab-group.mat-accent, .dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #3c588b;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #3c588b;
  --mat-tab-header-active-ripple-color: #3c588b;
  --mat-tab-header-inactive-ripple-color: #3c588b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3c588b;
  --mat-tab-header-active-hover-label-text-color: #3c588b;
  --mat-tab-header-active-focus-indicator-color: #3c588b;
  --mat-tab-header-active-hover-indicator-color: #3c588b;
}
.dark .mat-mdc-tab-group.mat-warn, .dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ff6c77;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ff6c77;
  --mat-tab-header-active-ripple-color: #ff6c77;
  --mat-tab-header-inactive-ripple-color: #ff6c77;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff6c77;
  --mat-tab-header-active-hover-label-text-color: #ff6c77;
  --mat-tab-header-active-focus-indicator-color: #ff6c77;
  --mat-tab-header-active-hover-indicator-color: #ff6c77;
}
.dark .mat-mdc-tab-group.mat-background-primary, .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #24aa2c;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark .mat-mdc-tab-group.mat-background-accent, .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #3c588b;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark .mat-mdc-tab-group.mat-background-warn, .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ff6c77;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3c588b;
  --mdc-checkbox-selected-hover-icon-color: #3c588b;
  --mdc-checkbox-selected-icon-color: #3c588b;
  --mdc-checkbox-selected-pressed-icon-color: #3c588b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3c588b;
  --mdc-checkbox-selected-hover-state-layer-color: #3c588b;
  --mdc-checkbox-selected-pressed-state-layer-color: #3c588b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #24aa2c;
  --mdc-checkbox-selected-hover-icon-color: #24aa2c;
  --mdc-checkbox-selected-icon-color: #24aa2c;
  --mdc-checkbox-selected-pressed-icon-color: #24aa2c;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-hover-state-layer-color: #24aa2c;
  --mdc-checkbox-selected-pressed-state-layer-color: #24aa2c;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff6c77;
  --mdc-checkbox-selected-hover-icon-color: #ff6c77;
  --mdc-checkbox-selected-icon-color: #ff6c77;
  --mdc-checkbox-selected-pressed-icon-color: #ff6c77;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-hover-state-layer-color: #ff6c77;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff6c77;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #24aa2c;
}
.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3c588b;
}
.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff6c77;
}
.dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #24aa2c;
  --mdc-filled-button-label-text-color: #000;
}
.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3c588b;
  --mdc-filled-button-label-text-color: #fff;
}
.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff6c77;
  --mdc-filled-button-label-text-color: #000;
}
.dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #24aa2c;
  --mdc-protected-button-label-text-color: #000;
}
.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3c588b;
  --mdc-protected-button-label-text-color: #fff;
}
.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff6c77;
  --mdc-protected-button-label-text-color: #000;
}
.dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #24aa2c;
}
.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3c588b;
}
.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff6c77;
}
.dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.dark .mat-mdc-button, .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-button.mat-primary, .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #24aa2c;
  --mat-mdc-button-ripple-color: rgba(36, 170, 44, 0.1);
}
.dark .mat-mdc-button.mat-accent, .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3c588b;
  --mat-mdc-button-ripple-color: rgba(60, 88, 139, 0.1);
}
.dark .mat-mdc-button.mat-warn, .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff6c77;
  --mat-mdc-button-ripple-color: rgba(255, 108, 119, 0.1);
}
.dark .mat-mdc-raised-button, .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-raised-button.mat-primary, .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark .mat-mdc-raised-button.mat-accent, .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-raised-button.mat-warn, .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #24aa2c;
  --mat-mdc-button-persistent-ripple-color: #24aa2c;
  --mat-mdc-button-ripple-color: rgba(36, 170, 44, 0.1);
}
.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3c588b;
  --mat-mdc-button-persistent-ripple-color: #3c588b;
  --mat-mdc-button-ripple-color: rgba(60, 88, 139, 0.1);
}
.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff6c77;
  --mat-mdc-button-persistent-ripple-color: #ff6c77;
  --mat-mdc-button-ripple-color: rgba(255, 108, 119, 0.1);
}
.dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-fab,
.dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark .mat-mdc-fab.mat-primary,
.dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark .mat-mdc-fab.mat-accent,
.dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark .mat-mdc-fab.mat-warn,
.dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark .mat-mdc-fab[disabled][disabled],
.dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.dark .mat-mdc-fab.mat-unthemed,
.dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark .mat-mdc-fab.mat-primary,
.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #24aa2c;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.dark .mat-mdc-fab.mat-accent,
.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #3c588b;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.dark .mat-mdc-fab.mat-warn,
.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ff6c77;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #24aa2c;
}
.dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #3c588b;
}
.dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ff6c77;
}
.dark {
  --mat-badge-background-color: #24aa2c;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}

.dark .mat-badge-accent {
  --mat-badge-background-color: #3c588b;
  --mat-badge-text-color: white;
}
.dark .mat-badge-warn {
  --mat-badge-background-color: #ff6c77;
  --mat-badge-text-color: white;
}
.dark {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}

.dark {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
}

.dark {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #24aa2c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(36, 170, 44, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(36, 170, 44, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(36, 170, 44, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #24aa2c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(36, 170, 44, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}

.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3c588b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(60, 88, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(60, 88, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(60, 88, 139, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(60, 88, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff6c77;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 108, 119, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 108, 119, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 108, 119, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 108, 119, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #3c588b;
}
.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ff6c77;
}
.dark {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.dark {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}

.dark {
  --mat-icon-color: inherit;
}

.dark .mat-icon.mat-primary {
  --mat-icon-color: #24aa2c;
}
.dark .mat-icon.mat-accent {
  --mat-icon-color: #3c588b;
}
.dark .mat-icon.mat-warn {
  --mat-icon-color: #ff6c77;
}
.dark {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}

.dark {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #24aa2c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #24aa2c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #24aa2c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #ff6c77;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #ff6c77;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3c588b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3c588b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3c588b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff6c77;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff6c77;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff6c77;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}

.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #24aa2c;
  --mat-toolbar-container-text-color: white;
}
.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #3c588b;
  --mat-toolbar-container-text-color: white;
}
.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ff6c77;
  --mat-toolbar-container-text-color: white;
}
.dark .mat-tree {
  background: #424242;
}
.dark .mat-tree-node,
.dark .mat-nested-tree-node {
  color: white;
}
.dark .mat-ripple {
  overflow: hidden;
  position: relative;
}
.dark .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.dark .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.dark .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .dark .mat-ripple-element {
  display: none;
}

.dark .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .dark .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.dark .cdk-overlay-container, .dark .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.dark .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.dark .cdk-overlay-container:empty {
  display: none;
}
.dark .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.dark .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.dark .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .dark .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.dark .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.dark .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.dark .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.dark .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.dark .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.dark .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.dark textarea.cdk-textarea-autosize {
  resize: none;
}
.dark textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.dark textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.dark .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.dark .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.dark .mat-focus-indicator {
  position: relative;
}
.dark .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.dark .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .dark {
  --mat-focus-indicator-display: block;
}

.dark .mat-mdc-focus-indicator {
  position: relative;
}
.dark .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.dark .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .dark {
  --mat-mdc-focus-indicator-display: block;
}

.dark .mat-badge-review .mat-badge-content {
  color: var(--fundmore-review-contrast-700);
  background: var(--fundmore-review-500);
}
.dark .mat-badge-accent .mat-badge-content {
  color: var(--fundmore-accent-contrast-700);
  background: var(--fundmore-accent-500);
}
.dark .mat-badge-warn .mat-badge-content {
  color: var(--fundmore-warn-contrast-700);
  background: var(--fundmore-warn-500);
}
.dark .mat-badge-success .mat-badge-content {
  color: var(--fundmore-success-contrast-700);
  background: var(--fundmore-success-500);
}

.mat-badge-primary .mat-badge-content {
  color: var(--fundmore-primary-contrast-700);
  background: var(--fundmore-primary-500);
}

.mat-badge-accent .mat-badge-content {
  color: var(--fundmore-accent-contrast-700);
  background: var(--fundmore-accent-500);
}

.mat-badge-success .mat-badge-content {
  color: var(--fundmore-success-contrast-700);
  background: var(--fundmore-success-500);
}

.mat-badge-review .mat-badge-content {
  color: var(--fundmore-review-contrast-700);
  background: var(--fundmore-review-500);
}

.mat-badge-warn .mat-badge-content {
  color: var(--fundmore-warn-contrast-700);
  background: var(--fundmore-warn-500);
}

.badge-outline {
  display: inline-block;
  min-width: 40px;
  padding: 1px 7px 2px;
  border: 1px solid;
  border-radius: 30px;
  text-align: center;
  font-size: 0.85rem;
}

.badge-outline-primary {
  color: var(--fundmore-primary-500);
  border-color: var(--fundmore-primary-500);
}

.badge-outline-accent {
  color: var(--fundmore-accent-500);
  border-color: var(--fundmore-accent-500);
}

.badge-outline-success {
  color: var(--fundmore-success-500);
  border-color: var(--fundmore-success-500);
}

.badge-outline-review {
  color: var(--fundmore-review-500);
  border-color: var(--fundmore-review-500);
}

.badge-outline-warn {
  color: var(--fundmore-warn-500);
  border-color: var(--fundmore-warn-500);
}

.fm-banner {
  width: 100%;
  height: var(--banner-height);
  padding: 8px;
  background-color: var(--fundmore-review-500);
  color: var(--fundmore-review-contrast-700);
  font-weight: 900;
}
.fm-banner .mat-icon,
.fm-banner .mat-icon.mat-icon-no-color,
.fm-banner .mat-mdc-button:not(:disabled) {
  color: var(--fundmore-review-contrast-700);
}

.xng-breadcrumb-trail {
  padding: 0 10px;
  background-color: var(--theme-active-bg);
  border-radius: 4px;
  line-height: 24px;
}

.xng-breadcrumb-item .xng-breadcrumb-link:hover {
  text-decoration: none;
}

.xng-breadcrumb-list {
  color: var(--theme-light-dark);
}
.xng-breadcrumb-list .xng-breadcrumb-trail {
  color: var(--theme-light-dark);
}

.button-group {
  display: inline-flex;
}
.button-group > button:not(:first-of-type, :last-of-type) {
  border-radius: 0;
}
.button-group > button:not(:last-of-type) {
  border-right: var(--theme-grey-border);
}
.button-group > button:first-of-type:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button-group > button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.button-group > button.mat-mdc-menu-trigger {
  min-width: auto;
  padding: 0 8px;
}
.button-group > button.mat-mdc-menu-trigger .mat-icon {
  color: var(--fundmore-primary-contrast-700);
  margin-right: unset;
  font-size: 24px;
  width: auto;
  height: auto;
}
.button-group > span > button {
  border-right: var(--theme-grey-border);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mat-mdc-raised-button.add-new-button {
  display: flex !important;
  justify-content: center;
  margin: 10px 16px;
}
.mat-mdc-raised-button.add-new-button .material-icons {
  margin-bottom: 3px;
}

.mat-mdc-icon-button.mat-icon-button-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}
.mat-mdc-icon-button.mat-icon-button-sm .mat-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  margin-left: unset;
}

.mat-mdc-icon-button.mat-icon-button-md {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.mat-mdc-icon-button.mat-icon-button-md .mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 22px;
  margin-left: unset;
}

.mat-mdc-icon-button.mat-icon-button-in-tab {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 2px;
}
.mat-mdc-icon-button.mat-icon-button-in-tab .mat-icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  margin-left: unset;
}

.add-button.mat-mdc-icon-button.active .mat-icon {
  color: var(--fundmore-muted-500);
}

.mdc-button.mat-mdc-button {
  display: inline-flex;
  align-items: center;
}
.mdc-button.mat-mdc-button .mat-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.disable-ripple .mat-mdc-button-persistent-ripple {
  display: none;
}

.dark .mat-mdc-raised-button.mat-primary {
  color: var(--fundmore-primary-contrast-500);
}

.fm-btn-sm.mdc-button {
  height: 28px;
  padding: 0 9px;
}

.basic-button-toggle-group {
  border: none;
  flex-wrap: wrap;
}
.basic-button-toggle-group .mat-button-toggle.basic-button-toggle {
  min-width: 120px;
  border-radius: 4px;
  height: 35px;
  border: var(--theme-grey-border);
  margin: 3px;
  padding: 0 2px;
}
.basic-button-toggle-group .mat-button-toggle.basic-button-toggle .mat-button-toggle-label-content {
  line-height: 35px;
}
.basic-button-toggle-group .mat-button-toggle.basic-button-toggle.mat-button-toggle-checked {
  border: 1px solid var(--fundmore-primary-500);
}

.buttons-container {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.buttons-container button {
  white-space: normal;
  line-height: normal;
}
.buttons-container button:not(:last-child) {
  margin-right: 8px;
}

.mat-mdc-card.fm-card {
  padding: 0;
  border: var(--theme-grey-border);
  border-radius: 10px;
  box-shadow: unset;
  overflow: hidden;
}
.mat-mdc-card.fm-card .mat-mdc-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  background-color: var(--theme-light-grey-bg);
  border-bottom: var(--theme-grey-border);
  font-family: "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0px 16px;
}
.mat-mdc-card.fm-card .mat-mdc-card-header .mat-mdc-card-title {
  margin-bottom: 0;
  font-size: 1rem;
}
.mat-mdc-card.fm-card .mat-mdc-card-header .mat-mdc-card-header-text {
  width: 100%;
}
.mat-mdc-card.fm-card .mat-mdc-card-content {
  padding: 16px;
}

fm-chat .mark,
fm-ava .mark {
  border-radius: 5px;
  word-break: normal;
  background-color: color-mix(in srgb, var(--fundmore-muted-500) 50%, transparent);
}
fm-chat .reverse-message > div > .message-content > .mark,
fm-ava .reverse-message > div > .message-content > .mark {
  background-color: var(--fundmore-primary-700);
  color: var(--fundmore-primary-contrast-700);
}

mat-checkbox.mat-mdc-checkbox {
  padding: 4px;
  --mdc-checkbox-state-layer-size: 20px;
}
mat-checkbox.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--theme-primary-contrast-500);
}
mat-checkbox.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: var(--theme-accent-contrast-500);
}
mat-checkbox.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: var(--theme-warn-contrast-500);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  border-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786));
  background-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, #018786));
}

.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--fundmore-primary-contrast-500);
}

.mat-mdc-dialog-container {
  position: relative;
}
.mat-mdc-dialog-container h2 {
  font-size: 1rem;
  font-family: "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: var(--theme-light-dark-max);
}
.mat-mdc-dialog-container p {
  color: var(--theme-light-dark-max);
}
.mat-mdc-dialog-container .mat-mdc-dialog-title.mdc-dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  height: 40px;
  padding-left: 24px;
  padding-right: 0;
  padding-bottom: 0;
  background-color: var(--theme-grey-bg);
}
.mat-mdc-dialog-container .mat-mdc-dialog-title.mdc-dialog__title h3 {
  font-size: 1rem;
  margin-right: auto;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title.mdc-dialog__title + mat-progress-bar {
  right: 0;
  left: 0;
  margin-top: -10px;
}

.mat-dialog-lg .mat-mdc-dialog-container {
  padding: 0;
}
.mat-dialog-lg .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0;
}
.mat-dialog-lg .mat-mdc-dialog-content {
  height: 100%;
  max-height: none;
  margin: 0;
  overflow: auto;
}
.mat-dialog-lg .mdc-dialog .mat-mdc-dialog-content {
  padding: 10px 12px;
}

.mat-mdc-dialog-actions > button {
  min-width: 100px;
}

.cdk-drag-preview {
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.4;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-menu-item.mat-menu-item-multiline {
  display: flex;
  height: auto;
  min-height: 64px;
  align-items: center;
  text-overflow: initial;
  white-space: normal;
  line-height: 24px;
}
.mat-mdc-menu-item.mat-menu-item-multiline .mdc-list-item__primary-text {
  display: flex;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.no-max-width {
  max-width: unset;
  max-height: 450px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.no-max-width .popover-button-group {
  position: sticky;
  bottom: 0;
  background-color: var(--theme-content-bg);
  padding: 8px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.min-content-width {
  max-width: min-content;
}

select {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

input {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border: var(--theme-grey-border);
  border-radius: 5px;
  color: var(--theme-light-dark-max);
  background: transparent;
  min-height: 1rem;
  outline: unset;
  padding: 4px;
  width: 100%;
}
input.mat-mdc-input-element {
  color: var(--theme-light-dark-max);
}
input.mat-mdc-input-element.ng-touched.ng-invalid {
  border-color: var(--fundmore-warn-500);
}
input.mat-mdc-input-element[readonly=true] {
  border: none;
}
input[type=number] {
  text-align: right;
}

.form-edit-mode.form-edit-mode-enabled table:not(.table-edit-inline) mat-form-field .mat-mdc-form-field-infix > span:not(.mdc-floating-label) {
  display: none;
}
.form-edit-mode.form-edit-mode-enabled tbody .mat-mdc-header-cell,
.form-edit-mode.form-edit-mode-enabled tbody .mat-mdc-cell {
  border-color: transparent;
}
.form-edit-mode.form-edit-mode-enabled .custom-fields-list > .field-container {
  border-color: transparent;
}
.form-edit-mode.form-edit-mode-enabled .compact-list > .field-container {
  height: 60px;
}
.form-edit-mode.form-edit-mode-enabled .compact-list > .field-container .input {
  width: 100%;
}
.form-edit-mode.form-edit-mode-enabled .read-only-field {
  text-align: left;
}
.form-edit-mode:not(.form-edit-mode-enabled) span.required {
  display: none;
}
.form-edit-mode:not(.form-edit-mode-enabled) mat-slide-toggle:not(.exclude-edit-mode),
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.exclude-edit-mode),
.form-edit-mode:not(.form-edit-mode-enabled) label:not(.exclude-edit-mode) {
  pointer-events: none;
}
.form-edit-mode:not(.form-edit-mode-enabled) mat-slide-toggle:not(.exclude-edit-mode) mat-select,
.form-edit-mode:not(.form-edit-mode-enabled) mat-slide-toggle:not(.exclude-edit-mode) [matinput],
.form-edit-mode:not(.form-edit-mode-enabled) mat-slide-toggle:not(.exclude-edit-mode) [matPrefix],
.form-edit-mode:not(.form-edit-mode-enabled) mat-slide-toggle:not(.exclude-edit-mode) [matSuffix],
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.exclude-edit-mode) mat-select,
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.exclude-edit-mode) [matinput],
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.exclude-edit-mode) [matPrefix],
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.exclude-edit-mode) [matSuffix],
.form-edit-mode:not(.form-edit-mode-enabled) label:not(.exclude-edit-mode) mat-select,
.form-edit-mode:not(.form-edit-mode-enabled) label:not(.exclude-edit-mode) [matinput],
.form-edit-mode:not(.form-edit-mode-enabled) label:not(.exclude-edit-mode) [matPrefix],
.form-edit-mode:not(.form-edit-mode-enabled) label:not(.exclude-edit-mode) [matSuffix] {
  display: none;
}
.form-edit-mode:not(.form-edit-mode-enabled) mat-checkbox {
  pointer-events: none;
}
.form-edit-mode:not(.form-edit-mode-enabled) .mdc-line-ripple {
  display: none;
}
.form-edit-mode:not(.form-edit-mode-enabled) .custom-fields-list > .field-container {
  border-color: color-mix(in srgb, var(--fundmore-muted-500) 30%, transparent);
}
.form-edit-mode:not(.form-edit-mode-enabled) .custom-fields-list > .field-container .input {
  align-self: center;
}
.form-edit-mode:not(.form-edit-mode-enabled) .compact-list > .field-container .input {
  align-self: end;
}
.form-edit-mode:not(.form-edit-mode-enabled) mat-form-field:not(.search) .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.form-edit-mode:not(.form-edit-mode-enabled) .read-only-field {
  text-align: right;
}

.default-field {
  border: unset;
  margin: unset;
  padding: unset;
}

.mat-mdc-form-field {
  line-height: initial;
  width: 100%;
}
.mat-mdc-form-field .mdc-line-ripple {
  bottom: 0.25em;
}
.mat-mdc-form-field.no-underline .mdc-line-ripple {
  height: 0px;
}
.mat-mdc-form-field.no-underline .mdc-line-ripple::before {
  border: none;
}
.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper {
  margin-top: -4px;
}
.mat-mdc-form-field .mat-mdc-select-arrow-wrapper {
  width: 24px;
  height: 19px;
  justify-content: center;
}

.mat-mdc-table .mat-mdc-form-field-subscript-wrapper,
.search .mat-mdc-form-field-subscript-wrapper,
.roles .mat-mdc-form-field-subscript-wrapper,
.enter-constant .mat-mdc-form-field-subscript-wrapper {
  margin-top: -1.2em;
}

mat-form-field.form-field-has-symbol-prefix .mat-mdc-form-field-icon-prefix {
  width: 14px;
}
mat-form-field.form-field-has-symbol-prefix .mdc-floating-label {
  left: -14px;
}
mat-form-field.form-field-number .mat-mdc-form-field-infix {
  text-align: right;
}
mat-form-field.form-field-number .mat-mdc-form-field-infix .mdc-floating-label {
  text-align: left;
}

.form-container-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
.form-container-wrap.form-read-only .mdc-line-ripple {
  display: none;
}

.input-option {
  display: flex;
  align-items: center;
}
.input-option .or {
  padding: 0 20px;
}

.unit-wrapper {
  display: inline-flex;
  position: relative;
  max-width: 250px;
}
.unit-wrapper .unit-input {
  padding-left: 24px;
  text-align: right;
}
.unit-wrapper .unit {
  position: absolute;
  background-color: var(--theme-grey-bg);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  min-width: 70px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unit-wrapper .unit.mat-datepicker-toggle {
  padding: unset;
}
.unit-wrapper.after {
  padding-right: 0;
}
.unit-wrapper.after .unit-input {
  padding-left: 10px;
  width: calc(100% - 70px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.unit-wrapper.after .unit {
  right: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dollar-input,
.date-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
}
.dollar-input input,
.date-input input {
  border: var(--theme-grey-border);
}

.required-star {
  color: var(--fundmore-warn-500) !important;
}

.input-align-to-label .mat-mdc-form-field-infix {
  padding-top: 5px;
  padding-bottom: 10px;
}
.input-align-to-label .mat-mdc-form-field-infix .mat-mdc-input-element {
  padding: 0;
  min-height: unset;
}
.input-align-to-label .dollar-input {
  justify-content: flex-start;
}
.input-align-to-label .dollar-input span[matprefix] {
  line-height: 0.85rem;
  margin-right: 5px;
}
.input-align-to-label .dollar-input span[matsuffix] {
  margin-left: 5px;
}
.input-align-to-label .dollar-input input {
  border: unset;
}
.input-align-to-label .mat-mdc-form-field-icon-prefix,
.input-align-to-label .mat-mdc-form-field-icon-suffix {
  position: relative;
  top: -2px;
}

.inline-form .mat-mdc-form-field-infix {
  border: 0;
}
.inline-form .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.inline-form .mat-mdc-text-field-wrapper {
  padding-bottom: 0;
}
.inline-form .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
  padding-top: 0;
  align-items: center;
}
.inline-form .mat-mdc-form-field.date .mat-mdc-text-field-wrapper {
  padding-right: 0;
}
.inline-form .mat-mdc-form-field.date .mat-mdc-form-field-flex {
  align-items: center;
}
.inline-form .mat-mdc-form-field.date .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  border-top: unset;
  display: flex;
  padding-bottom: unset;
}
.inline-form .mat-mdc-form-field.date .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-icon-sm {
  cursor: pointer;
}
.inline-form .mat-mdc-form-field.date .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
  width: 40px;
  height: 40px;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-form.fm-table tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
  border: 0;
}

.clear-input .mat-mdc-form-field-icon-suffix {
  display: inline-flex;
  align-items: center;
  line-height: 24px;
}
.clear-input .mat-mdc-form-field-icon-suffix .mat-icon {
  cursor: pointer;
  font-size: 16px;
}

.editable-list-item {
  display: flex;
  padding: 0 5px;
  width: 100%;
  align-self: center;
}
.editable-list-item.first {
  min-width: 210px;
  padding-left: 10px;
}
.editable-list-item.end {
  padding-right: 20px;
}
.editable-list-item.center {
  justify-content: center;
  align-items: center;
}
.editable-list-item.small {
  width: 50%;
}
.editable-list-item.dollar {
  white-space: nowrap;
  justify-content: flex-end;
}
.editable-list-item .dollar-input input, .editable-list-item.dollar-input input {
  text-align: right;
  padding-right: 3px;
}
.editable-list-item input.mat-mdc-input-element[readonly=true] {
  border: none;
}
.editable-list-item input.mat-mdc-input-element.ng-touched.ng-invalid {
  border-color: var(--fundmore-warn-500);
}
.editable-list-item input.mat-mdc-input-element.number-field {
  text-align: right;
}

.select-all-border {
  border-bottom: 1px solid var(--fundmore-muted-400);
}

mat-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  padding-bottom: 0;
}
mat-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  border-top: 0;
}
mat-form-field.mat-form-field-appearance-outline.ng-invalid .mat-mdc-text-field-wrapper {
  border-color: var(--fundmore-warn-500);
}

.table-edit-inline mat-form-field {
  line-height: normal;
}
.table-edit-inline mat-form-field .mat-mdc-form-field-icon-prefix {
  position: absolute;
}
.table-edit-inline mat-form-field .mat-mdc-form-field-infix > span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.table-edit-inline mat-form-field:not(.disabled):hover .mat-mdc-form-field-infix > span:not(.mdc-floating-label), .table-edit-inline mat-form-field:not(.disabled):active .mat-mdc-form-field-infix > span:not(.mdc-floating-label), .table-edit-inline mat-form-field:not(.disabled):focus .mat-mdc-form-field-infix > span:not(.mdc-floating-label), .table-edit-inline mat-form-field:not(.disabled):focus-within .mat-mdc-form-field-infix > span:not(.mdc-floating-label) {
  display: none;
}
.table-edit-inline mat-form-field:not(:hover):not(:active):not(:focus):not(:focus-within) .mat-mdc-form-field-icon-prefix,
.table-edit-inline mat-form-field:not(:hover):not(:active):not(:focus):not(:focus-within) .mat-mdc-form-field-icon-suffix,
.table-edit-inline mat-form-field:not(:hover):not(:active):not(:focus):not(:focus-within) .mat-mdc-form-field-infix > :not(span),
.table-edit-inline mat-form-field:not(:hover):not(:active):not(:focus):not(:focus-within) .mdc-floating-label {
  display: none;
}
.table-edit-inline mat-form-field:not(:hover):not(:active):not(:focus):not(:focus-within):not(.mat-form-field-invalid) .mdc-line-ripple {
  display: none;
}
.table-edit-inline mat-form-field.mat-form-field-invalid:not(.has-mat-error):not(.mat-error-position-static) .mat-mdc-form-field-subscript-wrapper {
  margin-top: -1.2em;
}
.table-edit-inline mat-form-field .mat-mdc-form-field-icon-suffix {
  position: absolute;
  right: 8px;
}
.table-edit-inline mat-form-field.text-right-icon-suffix input,
.table-edit-inline mat-form-field.text-right-icon-suffix mat-select {
  padding-right: 16px;
}
.table-edit-inline mat-form-field.text-right-icon-suffix fm-constant-or-any-select input {
  padding-right: 24px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 24px;
}

.mat-mdc-form-field-infix {
  min-height: 40px;
}

.text-right .mat-mdc-form-field-infix {
  justify-content: flex-end;
}

.mat-mdc-form-field .mdc-text-field.mat-mdc-text-field-wrapper {
  padding: 0 8px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--theme-app-bg, #fafafa);
}

.mat-mdc-text-field-wrapper.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset;
}

.highlight,
mat-form-field.highlight {
  background-color: var(--theme-grey-bg);
}
.highlight .mat-mdc-text-field-wrapper,
mat-form-field.highlight .mat-mdc-text-field-wrapper {
  background-color: var(--theme-grey-bg);
  border-bottom: solid 1px;
  border-color: var(--fundmore-primary-500);
}

mat-form-field.mat-error-position-static .mat-mdc-form-field-error-wrapper {
  position: static;
  padding-left: 0;
}
mat-form-field.mat-error-position-static.mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper {
  display: initial;
  margin: 0;
}
mat-form-field.mat-error-position-static.mat-form-field-invalid .mat-mdc-form-field-bottom-align::before {
  height: 0;
}
mat-form-field.mat-error-position-static .mat-mdc-form-field-bottom-align::before {
  display: block;
}

button:not([disabled]):not([color]) mat-icon,
button:not([disabled]):not([color]) .mat-icon-no-color {
  color: var(--theme-light-dark);
}

*:not(button) > .mat-icon-no-color,
*:not(button) > .material-icons {
  color: var(--theme-light-dark);
}

.mat-mdc-icon-button.mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.material-icons.mat-icon-sm {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  font-size: 16px;
  line-height: 16px;
}
.mat-mdc-icon-button.mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.material-icons.mat-icon-md {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
}
.mat-mdc-icon-button.mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg,
.material-icons.mat-icon-lg {
  width: 64px;
  height: 64px;
  font-size: 64px;
  line-height: 64px;
}

.mdc-list.mat-mdc-list-base {
  --mdc-list-list-item-label-text-line-height: 17px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-supporting-text-line-height: 14px;
  --mdc-list-list-item-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-line-height: 15px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
}
.mdc-list.mat-mdc-list-base .mdc-list-item.no-padding {
  padding: 0;
}
.mdc-list.mat-mdc-list-base .mdc-list-item.mdc-list-item--disabled {
  cursor: not-allowed !important;
  text-decoration: none;
  pointer-events: none;
}
.mdc-list.mat-mdc-list-base .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin: 0;
}
.mdc-list.mat-mdc-list-base.mat-mdc-selection-list {
  min-width: 184px;
}
.mdc-list.mat-mdc-list-base.mat-mdc-selection-list .mdc-list-item {
  height: 48px;
  padding-right: 8px;
}
.mdc-list.mat-mdc-list-base .mat-mdc-list-item .mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--theme-primary-contrast-500);
}
.mdc-list.mat-mdc-list-base .mat-mdc-list-item.mat-accent .mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: var(--theme-accent-contrast-500);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

mat-progress-bar.mat-mdc-progress-bar,
.dark mat-progress-bar.mat-mdc-progress-bar {
  position: absolute;
  z-index: 2;
}

.mat-mdc-select-panel {
  min-width: 200px;
}
.mat-mdc-select-panel.no-max-width {
  max-width: none;
}

ngx-mat-select-search .mat-select-search-clear {
  top: 0 !important;
}
ngx-mat-select-search.has-filter-button .mat-select-search-clear {
  right: 44px;
}
ngx-mat-select-search .mat-select-search-inner {
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  border-bottom: var(--theme-grey-border);
}
ngx-mat-select-search .mat-select-search-inner .mat-select-search-toggle-all-checkbox {
  padding-left: 16px;
  padding-right: 8px;
}
ngx-mat-select-search .mat-select-search-inner-row {
  display: flex;
  width: 100%;
}

.mat-mdc-option-multiple.mat-mdc-option-multiple-with-mat-icon mat-pseudo-checkbox {
  margin-right: 0;
}

.mat-drawer-container {
  background-color: transparent;
  position: unset !important;
}

.sidenav-details {
  width: 30%;
}

.sidenav-content-scrollable-container {
  height: calc(100vh - var(--application-top-margin));
  padding-bottom: 70px;
  overflow: auto;
}
.sidenav-content-scrollable-container.sidenav-open {
  margin-right: calc(30% + 69px);
}
.sidenav-collapsed .sidenav-content-scrollable-container.sidenav-open {
  margin-right: calc(30% + 16.5px);
}
.sidenav-content-scrollable-container.sidenav-open > .sidenav-content-scrollable-inner-container {
  min-width: calc(100vw - 230px);
}
.sidenav-collapsed .sidenav-content-scrollable-container.sidenav-open > .sidenav-content-scrollable-inner-container {
  min-width: calc(100vw - 55px);
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme-grey-bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: color-mix(in srgb, var(--fundmore-muted-500) 60%, transparent);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--fundmore-muted-500);
}

mat-tab-group.vertical-tabs > mat-tab-header {
  border-bottom: 0;
}
mat-tab-group.vertical-tabs > mat-tab-header .mat-mdc-tab-labels {
  flex-direction: column;
}
mat-tab-group.vertical-tabs > mat-tab-header .mat-mdc-tab-labels .mat-mdc-tab {
  justify-content: flex-start;
  border-bottom: 0;
}
mat-tab-group.vertical-tabs > mat-tab-header .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active {
  background-color: var(--theme-grey-bg);
  border-radius: 5px;
}
mat-tab-group.vertical-tabs > mat-tab-header .mat-mdc-tab-labels .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: var(--theme-light-dark-max);
}
mat-tab-group.vertical-tabs > mat-tab-header .mat-ink-bar {
  display: none;
}
mat-tab-group.vertical-tabs > mat-tab-header .mdc-tab-indicator__content--underline {
  border: none;
}
mat-tab-group:not(.vertical-tabs) .mat-mdc-tab-header .mat-mdc-tab {
  font-size: 0.85rem;
}
mat-tab-group:not(.vertical-tabs) .mat-mdc-tab-header .mat-mdc-tab-label-container {
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
}
mat-tab-group .mat-mdc-tab-body {
  padding: 8px;
}
mat-tab-group .mat-mdc-tab-header .mat-mdc-tab {
  font-family: "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.85rem;
  opacity: 1;
  padding: 0 16px;
  min-width: unset;
  margin-top: 5px;
  height: 35px;
}
mat-tab-group .mat-mdc-tab-header .mat-mdc-tab.fm-tab-options-button {
  padding-left: 0;
  padding-right: 0;
}
mat-tab-group .mat-mdc-subheader {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  white-space: nowrap;
  text-align: center;
}
mat-tab-group.has-add-button mat-tab-header {
  margin-right: 60px;
}

.fm-tab-add-button {
  position: absolute !important;
  top: 0px;
  right: 10px;
}

.fm-table {
  border-spacing: 0;
}
.fm-table thead tr th,
.fm-table tbody tr td {
  padding-right: 8px;
  padding-left: 8px;
}
.fm-table:not(.mat-mdc-table) thead tr {
  height: 48px;
}
.fm-table:not(.mat-mdc-table) thead tr th {
  text-align: left;
}
.fm-table:not(.mat-mdc-table) tbody td {
  height: 52px;
}
.fm-table tbody tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.fm-table tbody tr:last-child td {
  border-bottom: 0;
}
.fm-table .mat-mdc-no-data-row td {
  padding: 8px;
}
.fm-table .mat-mdc-header-cell.mdc-data-table__cell,
.fm-table .mat-mdc-cell.mdc-data-table__cell,
.fm-table .mat-mdc-footer-cell.mdc-data-table__cell {
  text-overflow: unset;
}
.fm-table tr .button-open-sidenav {
  background-color: var(--theme-light-max);
  border-radius: 4px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  visibility: hidden;
  padding: unset;
  margin-left: 8px;
}
.fm-table tr .button-open-sidenav .mat-icon {
  font-size: 16px;
  line-height: 24px;
}
.fm-table.fm-hover tr:hover {
  background-color: var(--theme-grey-bg);
}
.fm-table.fm-hover tr:hover .button-open-sidenav {
  visibility: visible;
}
.fm-table.mdc-data-table__table {
  min-width: max-content;
  width: 100%;
}
.fm-table .mat-column-options {
  width: 60px;
}

.mat-mdc-paginator-page-size {
  align-items: center;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}
.mat-mdc-tooltip.pre-wrap {
  white-space: pre-wrap;
}
.mat-mdc-tooltip.text-left .mdc-tooltip__surface {
  text-align: left;
}

.mdc-tooltip.no-max-width .mdc-tooltip__surface {
  max-width: none;
}

.bg-content {
  background-color: var(--theme-content-bg) !important;
}

.bg-grey {
  background-color: var(--theme-grey-bg) !important;
}

.bg-light-grey {
  background-color: var(--theme-light-grey-bg) !important;
}

.bg-muted {
  background-color: var(--fundmore-muted-500) !important;
}

.bg-review {
  background-color: var(--fundmore-review-500) !important;
}

.bg-success {
  background-color: var(--fundmore-success-500) !important;
}

.bg-warn {
  background-color: var(--fundmore-warn-500) !important;
}

.bg-none {
  background: none !important;
}

.border {
  border: var(--theme-grey-border);
}

.border-primary {
  border-color: var(--fundmore-primary-500) !important;
}

.border-top {
  border-top: var(--theme-grey-border) !important;
}

.border-right {
  border-right: var(--theme-grey-border);
}

.border-bottom {
  border-bottom: var(--theme-grey-border);
}

.border-bottom-accent {
  border-bottom-color: var(--fundmore-accent-500) !important;
}

.border-left {
  border-left: var(--theme-grey-border);
}

.border-rounded {
  border-radius: 10px;
}

.border-rounded-sm {
  border-radius: 5px;
}

.border-rounded-0 {
  border-radius: 0 !important;
}

.border-0 {
  border: 0;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
  width: 100%;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-expand {
  flex: 1;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-auto-flex {
  height: 0px;
  min-height: 0px;
  flex: 1 1 0px;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.maxw-100 {
  max-width: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100%;
}

.h-0 {
  height: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.h-4 {
  height: 4px !important;
}

.h-5 {
  height: 5px !important;
}

.h-8 {
  height: 8px !important;
}

.h-10 {
  height: 10px !important;
}

.h-16 {
  height: 16px !important;
}

.h-20 {
  height: 20px !important;
}

.h-28 {
  height: 28px !important;
}

.h-32 {
  height: 32px !important;
}

.h-50 {
  height: 50px !important;
}

.h-64 {
  height: 64px !important;
}

.w-0 {
  width: 0 !important;
}

.w-4 {
  width: 4px !important;
}

.w-5 {
  width: 5px !important;
}

.w-8 {
  width: 8px !important;
}

.w-10 {
  width: 10px !important;
}

.w-16 {
  width: 16px !important;
}

.w-20 {
  width: 20px !important;
}

.w-28 {
  width: 28px !important;
}

.w-32 {
  width: 32px !important;
}

.w-50 {
  width: 50px !important;
}

.w-64 {
  width: 64px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-2 {
  margin: 2px !important;
}

.mt-2,
.my-2 {
  margin-top: 2px !important;
}

.mr-2,
.mx-2 {
  margin-right: 2px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 2px !important;
}

.ml-2,
.mx-2 {
  margin-left: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.mt-4,
.my-4 {
  margin-top: 4px !important;
}

.mr-4,
.mx-4 {
  margin-right: 4px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 4px !important;
}

.ml-4,
.mx-4 {
  margin-left: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.m-8 {
  margin: 8px !important;
}

.mt-8,
.my-8 {
  margin-top: 8px !important;
}

.mr-8,
.mx-8 {
  margin-right: 8px !important;
}

.mb-8,
.my-8 {
  margin-bottom: 8px !important;
}

.ml-8,
.mx-8 {
  margin-left: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px !important;
}

.m-16 {
  margin: 16px !important;
}

.mt-16,
.my-16 {
  margin-top: 16px !important;
}

.mr-16,
.mx-16 {
  margin-right: 16px !important;
}

.mb-16,
.my-16 {
  margin-bottom: 16px !important;
}

.ml-16,
.mx-16 {
  margin-left: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20,
.my-20 {
  margin-top: 20px !important;
}

.mr-20,
.mx-20 {
  margin-right: 20px !important;
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important;
}

.ml-20,
.mx-20 {
  margin-left: 20px !important;
}

.m-28 {
  margin: 28px !important;
}

.mt-28,
.my-28 {
  margin-top: 28px !important;
}

.mr-28,
.mx-28 {
  margin-right: 28px !important;
}

.mb-28,
.my-28 {
  margin-bottom: 28px !important;
}

.ml-28,
.mx-28 {
  margin-left: 28px !important;
}

.m-32 {
  margin: 32px !important;
}

.mt-32,
.my-32 {
  margin-top: 32px !important;
}

.mr-32,
.mx-32 {
  margin-right: 32px !important;
}

.mb-32,
.my-32 {
  margin-bottom: 32px !important;
}

.ml-32,
.mx-32 {
  margin-left: 32px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50,
.my-50 {
  margin-top: 50px !important;
}

.mr-50,
.mx-50 {
  margin-right: 50px !important;
}

.mb-50,
.my-50 {
  margin-bottom: 50px !important;
}

.ml-50,
.mx-50 {
  margin-left: 50px !important;
}

.m-64 {
  margin: 64px !important;
}

.mt-64,
.my-64 {
  margin-top: 64px !important;
}

.mr-64,
.mx-64 {
  margin-right: 64px !important;
}

.mb-64,
.my-64 {
  margin-bottom: 64px !important;
}

.ml-64,
.mx-64 {
  margin-left: 64px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-2 {
  padding: 2px !important;
}

.pt-2,
.py-2 {
  padding-top: 2px !important;
}

.pr-2,
.px-2 {
  padding-right: 2px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 2px !important;
}

.pl-2,
.px-2 {
  padding-left: 2px !important;
}

.p-4 {
  padding: 4px !important;
}

.pt-4,
.py-4 {
  padding-top: 4px !important;
}

.pr-4,
.px-4 {
  padding-right: 4px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 4px !important;
}

.pl-4,
.px-4 {
  padding-left: 4px !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.p-8 {
  padding: 8px !important;
}

.pt-8,
.py-8 {
  padding-top: 8px !important;
}

.pr-8,
.px-8 {
  padding-right: 8px !important;
}

.pb-8,
.py-8 {
  padding-bottom: 8px !important;
}

.pl-8,
.px-8 {
  padding-left: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px !important;
}

.pr-10,
.px-10 {
  padding-right: 10px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px !important;
}

.pl-10,
.px-10 {
  padding-left: 10px !important;
}

.p-16 {
  padding: 16px !important;
}

.pt-16,
.py-16 {
  padding-top: 16px !important;
}

.pr-16,
.px-16 {
  padding-right: 16px !important;
}

.pb-16,
.py-16 {
  padding-bottom: 16px !important;
}

.pl-16,
.px-16 {
  padding-left: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20,
.py-20 {
  padding-top: 20px !important;
}

.pr-20,
.px-20 {
  padding-right: 20px !important;
}

.pb-20,
.py-20 {
  padding-bottom: 20px !important;
}

.pl-20,
.px-20 {
  padding-left: 20px !important;
}

.p-28 {
  padding: 28px !important;
}

.pt-28,
.py-28 {
  padding-top: 28px !important;
}

.pr-28,
.px-28 {
  padding-right: 28px !important;
}

.pb-28,
.py-28 {
  padding-bottom: 28px !important;
}

.pl-28,
.px-28 {
  padding-left: 28px !important;
}

.p-32 {
  padding: 32px !important;
}

.pt-32,
.py-32 {
  padding-top: 32px !important;
}

.pr-32,
.px-32 {
  padding-right: 32px !important;
}

.pb-32,
.py-32 {
  padding-bottom: 32px !important;
}

.pl-32,
.px-32 {
  padding-left: 32px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50,
.py-50 {
  padding-top: 50px !important;
}

.pr-50,
.px-50 {
  padding-right: 50px !important;
}

.pb-50,
.py-50 {
  padding-bottom: 50px !important;
}

.pl-50,
.px-50 {
  padding-left: 50px !important;
}

.p-64 {
  padding: 64px !important;
}

.pt-64,
.py-64 {
  padding-top: 64px !important;
}

.pr-64,
.px-64 {
  padding-right: 64px !important;
}

.pb-64,
.py-64 {
  padding-bottom: 64px !important;
}

.pl-64,
.px-64 {
  padding-left: 64px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-heading {
  font-family: "Raleway", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-feature-settings: "lnum" 1;
}

.text-dark {
  color: var(--theme-light-dark) !important;
}

.text-primary {
  color: var(--fundmore-primary-500) !important;
}

.text-accent {
  color: var(--fundmore-accent-500) !important;
}

.text-success {
  color: var(--fundmore-success-500) !important;
}

.text-warn {
  color: var(--fundmore-warn-500) !important;
}

.text-review {
  color: var(--fundmore-review-500) !important;
}

.text-muted {
  color: var(--fundmore-muted-500) !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.font-weight-bold {
  font-weight: 700;
}

.align-middle {
  vertical-align: middle;
}

.cursor-auto {
  cursor: auto !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-big-size {
  font-size: 1rem !important;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}

fieldset[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.field-container {
  height: 52px;
}
.field-container .highlight-container {
  width: 100%;
}
.field-container .input {
  width: 50%;
}

.list-right > .field-container > .highlight-container > .input > .mat-mdc-form-field {
  text-align: right;
}

.compact-list .field-container .highlight-container {
  width: calc(25% - 15px);
}
.compact-list .field-container .long-label,
.compact-list .field-container .input {
  padding: 0;
}
.compact-list .field-container .input {
  width: 100%;
}

.contrast {
  --mdc-snackbar-container-color: $fundmore-grey-bg !important;
  --mdc-snackbar-supporting-text-color: $fundmore-dark !important;
}

.mat-step-header:focus {
  background-color: transparent;
}

.mat-step-icon .mat-icon {
  color: var(--fundmore-accent-contrast-700);
}

/* stylelint-disable-next-line selector-type-no-unknown */
editor .tox-tinymce {
  height: 100% !important;
  border: var(--theme-grey-border);
  border-radius: 0;
}

.popover-panel .mat-expansion-panel-header {
  padding-left: 1rem;
  padding-right: 1rem;
}
.popover-panel .mat-expansion-panel-header.mat-expanded {
  height: var(--mat-expansion-header-collapsed-state-height);
}
.popover-panel .mat-expansion-panel-body {
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--theme-grey-bg) !important;
}