@use '../variables' as *;

.mat-mdc-select-panel {
  min-width: 200px;

  &.no-max-width {
    max-width: none;
  }
}

ngx-mat-select-search {
  .mat-select-search-clear {
    top: 0 !important;
  }

  &.has-filter-button .mat-select-search-clear {
    right: 44px;
  }

  .mat-select-search-inner {
    display: flex;
    overflow: hidden;
    flex-shrink: 0;
    border-bottom: $fundmore-grey-border;

    .mat-select-search-toggle-all-checkbox {
      padding-left: 16px;
      padding-right: 8px;
    }
  }

  .mat-select-search-inner-row {
    display: flex;
    width: 100%;
  }
}

.mat-mdc-option-multiple.mat-mdc-option-multiple-with-mat-icon {
  mat-pseudo-checkbox {
    margin-right: 0;
  }
}
