@use '../variables' as *;

.popover-panel {
  .mat-expansion-panel-header {
    padding-left: 1rem;
    padding-right: 1rem;
    &.mat-expanded {
      height: var(--mat-expansion-header-collapsed-state-height);
    }
  }
  .mat-expansion-panel-body {
    padding-bottom: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $fundmore-grey-bg !important;
  }
}
