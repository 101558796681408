.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-expand {
  flex: 1;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-12 {
  gap: 12px;
}
