@use '../variables' as *;

select {
  font-family: $font-family-base;
}

input {
  font-family: $font-family-base;
  border: $fundmore-grey-border;
  border-radius: $border-radius-sm;
  color: var(--theme-light-dark-max);

  background: transparent;

  min-height: 1rem;
  outline: unset;
  padding: 4px;
  width: 100%;

  &.mat-mdc-input-element {
    color: var(--theme-light-dark-max);

    &.ng-touched.ng-invalid {
      border-color: var(--fundmore-warn-500);
    }

    &[readonly='true'] {
      border: none;
    }
  }

  &[type='number'] {
    text-align: right;
  }
}

.form-edit-mode {
  &.form-edit-mode-enabled {
    table:not(.table-edit-inline) {
      mat-form-field {
        .mat-mdc-form-field-infix > span:not(.mdc-floating-label) {
          display: none;
        }
      }
    }

    tbody {
      .mat-mdc-header-cell,
      .mat-mdc-cell {
        border-color: transparent;
      }
    }

    .custom-fields-list > .field-container {
      border-color: transparent;
    }

    .compact-list > .field-container {
      height: 60px;
      .input {
        width: 100%;
      }
    }

    .read-only-field {
      text-align: left;
    }
  }

  &:not(.form-edit-mode-enabled) {
    span.required {
      display: none;
    }

    mat-slide-toggle:not(.exclude-edit-mode),
    mat-form-field:not(.exclude-edit-mode),
    label:not(.exclude-edit-mode) {
      pointer-events: none;
      mat-select,
      [matinput],
      [matPrefix],
      [matSuffix] {
        display: none;
      }
    }

    mat-checkbox {
      pointer-events: none;
    }

    .mdc-line-ripple {
      display: none;
    }

    .custom-fields-list > .field-container {
      border-color: color-mix(in srgb, var(--fundmore-muted-500) 30%, transparent);

      .input {
        align-self: center;
      }
    }

    .compact-list > .field-container {
      .input {
        align-self: end;
      }
    }

    mat-form-field:not(.search) {
      .mdc-text-field--filled {
        &:not(.mdc-text-field--disabled) {
          background-color: transparent;
        }
      }
    }
    .read-only-field {
      text-align: right;
    }
  }
}

.default-field {
  border: unset;
  margin: unset;
  padding: unset;
}

.mat-mdc-form-field {
  line-height: initial;
  width: 100%;

  .mdc-line-ripple {
    bottom: 0.25em;
  }
  &.no-underline {
    .mdc-line-ripple {
      height: 0px;
    }
    .mdc-line-ripple::before {
      border: none;
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-subscript-wrapper {
      // this is needed for most form fields with error
      margin-top: -4px;
    }
  }

  .mat-mdc-select-arrow-wrapper {
    width: 24px;
    height: 19px;
    justify-content: center;
  }
}

.mat-mdc-table,
.search,
.roles,
.enter-constant {
  .mat-mdc-form-field-subscript-wrapper {
    margin-top: -1.2em;
  }
}

mat-form-field {
  &.form-field-has-symbol-prefix {
    .mat-mdc-form-field-icon-prefix {
      width: 14px;
    }

    .mdc-floating-label {
      left: -14px;
    }
  }

  // align numeric mat inputs to the right
  &.form-field-number .mat-mdc-form-field-infix {
    text-align: right;

    .mdc-floating-label {
      text-align: left;
    }
  }
}

.form-container-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;

  &.form-read-only {
    .mdc-line-ripple {
      display: none;
    }
  }
}

.input-option {
  display: flex;
  align-items: center;

  .or {
    padding: 0 20px;
  }
}

.unit-wrapper {
  display: inline-flex;
  position: relative;
  max-width: 250px;

  .unit-input {
    padding-left: 24px;
    text-align: right;
  }

  .unit {
    position: absolute;
    background-color: $fundmore-grey-bg;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 100%;
    min-width: 70px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.mat-datepicker-toggle {
      padding: unset;
    }
  }
  &.after {
    padding-right: 0;

    .unit-input {
      padding-left: 10px;
      width: calc(100% - 70px);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .unit {
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.dollar-input,
.date-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    border: $fundmore-grey-border;
  }
}

// TODO use the required attribute on inputs when using the mat-form-field component
.required-star {
  color: var(--fundmore-warn-500) !important;
}

.input-align-to-label {
  .mat-mdc-form-field-infix {
    padding-top: 5px;
    padding-bottom: 10px;

    .mat-mdc-input-element {
      padding: 0;
      min-height: unset;
    }
  }

  .dollar-input {
    justify-content: flex-start;
    span[matprefix] {
      line-height: $body-font-size;
      margin-right: 5px;
    }

    span[matsuffix] {
      margin-left: 5px;
    }

    input {
      border: unset;
    }
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    position: relative;
    top: -2px;
  }
}

.inline-form {
  .mat-mdc-form-field-infix {
    // hide the mat-label when the label is actually placed separately from the mat-form-field
    border: 0;
  }

  // TODO: remove?
  // this rule is not confusing, we should not use the floating label in this case
  // .mdc-floating-label {
  //   display: none;
  // }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding-top: 0;
    align-items: center;
  }

  .mat-mdc-form-field.date {
    .mat-mdc-text-field-wrapper {
      padding-right: 0;
    }

    .mat-mdc-form-field-flex {
      align-items: center;

      .mat-mdc-form-field-icon-suffix {
        border-top: unset;
        display: flex;
        padding-bottom: unset;

        .mat-icon-sm {
          cursor: pointer;
        }

        .mat-mdc-icon-button {
          width: 40px;
          height: 40px;
          padding: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.fm-table tbody tr td {
    padding-top: 0px;
    padding-bottom: 0px;
    border: 0;
  }
}

.clear-input {
  .mat-mdc-form-field-icon-suffix {
    display: inline-flex;
    align-items: center;
    line-height: 24px;

    .mat-icon {
      cursor: pointer;
      font-size: 16px;
    }
  }
}

.editable-list-item {
  display: flex;
  padding: 0 5px;
  width: 100%;
  align-self: center;

  &.first {
    min-width: 210px;
    padding-left: 10px;
  }

  &.end {
    padding-right: 20px;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.small {
    width: 50%;
  }

  &.dollar {
    white-space: nowrap;
    justify-content: flex-end;
  }

  .dollar-input,
  &.dollar-input {
    input {
      text-align: right;
      padding-right: 3px;
    }
  }

  input.mat-mdc-input-element {
    // border: $fundmore-grey-border;

    &[readonly='true'] {
      border: none;
    }

    &.ng-touched.ng-invalid {
      border-color: var(--fundmore-warn-500);
    }

    &.number-field {
      text-align: right;
    }
  }
}

.select-all-border {
  border-bottom: 1px solid var(--fundmore-muted-400);
}

// outline input style with no label
mat-form-field.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
  }

  &.ng-invalid {
    .mat-mdc-text-field-wrapper {
      border-color: var(--fundmore-warn-500);
    }
  }
}

// use for inline table editing; hovering on a table cell will display the form control
.table-edit-inline mat-form-field {
  line-height: normal;

  .mat-mdc-form-field-icon-prefix {
    position: absolute;
  }

  .mat-mdc-form-field-infix > span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:not(.disabled):hover,
  &:not(.disabled):active,
  &:not(.disabled):focus,
  &:not(.disabled):focus-within {
    .mat-mdc-form-field-infix > span:not(.mdc-floating-label) {
      display: none;
    }
  }

  &:not(:hover):not(:active):not(:focus):not(:focus-within) {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-infix > :not(span),
    .mdc-floating-label {
      display: none;
    }

    &:not(.mat-form-field-invalid) .mdc-line-ripple {
      display: none;
    }
  }

  &.mat-form-field-invalid:not(.has-mat-error):not(.mat-error-position-static) {
    .mat-mdc-form-field-subscript-wrapper {
      margin-top: -1.2em;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    position: absolute;
    right: 8px;
  }

  &.text-right-icon-suffix {
    input,
    mat-select {
      padding-right: 16px;
    }

    fm-constant-or-any-select input {
      padding-right: 24px;
    }
  }
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
  .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 24px;
}

.mat-mdc-form-field-infix {
  min-height: 40px;
}

.text-right {
  .mat-mdc-form-field-infix {
    justify-content: flex-end;
  }
}

.mat-mdc-form-field .mdc-text-field.mat-mdc-text-field-wrapper {
  padding: 0 8px;
}

.mdc-text-field--filled {
  &:not(.mdc-text-field--disabled) {
    background-color: var(--theme-app-bg, #fafafa);
  }
}
.mat-mdc-text-field-wrapper.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset;
}

// highlight form field
.highlight,
mat-form-field.highlight {
  background-color: $fundmore-grey-bg;
  .mat-mdc-text-field-wrapper {
    background-color: $fundmore-grey-bg;
    border-bottom: solid 1px;
    border-color: var(--fundmore-primary-500);
  }
}

mat-form-field.mat-error-position-static {
  .mat-mdc-form-field-error-wrapper {
    position: static;
    padding-left: 0;
  }

  &.mat-form-field-invalid {
    .mat-mdc-form-field-subscript-wrapper {
      display: initial;
      margin: 0;
    }

    .mat-mdc-form-field-bottom-align::before {
      height: 0;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: block;
  }
}
