@use '@angular/material' as mat;
@use '../variables' as *;
@use 'typography' as *;
@use 'theme-variables' as *;
@use 'sass:map';

$primary: mat.define-palette(map.get($colors, primary));
$accent: mat.define-palette(map.get($colors, accent));
$warn: mat.define-palette(map.get($colors, warn));

// Create the theme object (a Sass map containing all of the palettes).
$fundmoreai-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $custom-typography,
    density: 0,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fundmoreai-theme);
@include mat.core();

// workaround for https://github.com/angular/components/issues/26056 https://github.com/angular/components/issues/26153
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: var(--theme-accent-contrast-500);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500);
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: var(--theme-accent-contrast-500);
}

// TODO: not sure if this is proper way to override the default font
// removed from app.component
body {
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
