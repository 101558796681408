/* You can add global styles to this file, and also import other style files */
.mat-expansion-indicator {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

//the powerbi-report component can only take the styling from here (https://github.com/microsoft/powerbi-client-angular)
.report-container {
  height: 98%;
  margin: 8px auto;
}

.locked-form-control {
  position: relative;

  > * {
    pointer-events: none;
    cursor: default;
  }

  mat-select,
  mat-checkbox,
  &mat-checkbox,
  input {
    user-select: none;
  }

  &:after {
    content: attr(data-locked-tooltip);
    position: absolute;
    top: -80%;
    left: 50%;
    margin-left: -45px;
    padding: 4px;
    text-align: center;

    border-radius: 4px;
    background-color: #616161;
    font-size: 12px;
    color: #fff;
    line-height: 16px;

    transform: scale(0);
    transform-origin: center top;
  }

  &:hover:after {
    animation: mat-mdc-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
    transform: scale(1);
    opacity: 1;
    will-change: transform, opacity;
  }

  &.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  &.mat-mdc-form-field .mat-mdc-text-field-wrapper {
    &,
    .dark & {
      background-color: unset;
    }

    .mdc-line-ripple::before {
      border-bottom-color: rgba(0, 0, 0, 0.06);
    }

    .mat-mdc-form-field-input-control,
    .mat-mdc-select-value,
    fm-address-input input.mdc-text-field__input {
      color: rgba(0, 0, 0, 0.38);

      .dark & {
        color: rgba(255, 255, 255, 0.38);
      }
    }

    .mat-mdc-form-field-icon-suffix,
    mat-datepicker-toggle,
    .mdc-floating-label {
      pointer-events: none;
      cursor: default;
    }
  }

  mat-datepicker-toggle .mat-mdc-icon-button {
    --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);

    .dark & {
      --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
      --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
    }
  }

  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    border-color: transparent;
    background-color: var(--mdc-checkbox-disabled-selected-icon-color, rgba(0, 0, 0, 0.38));
  }

  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background {
    border-color: var(--mdc-checkbox-disabled-unselected-icon-color, rgba(0, 0, 0, 0.38));
    background-color: transparent;
  }

  &.mat-mdc-slide-toggle {
    .mdc-switch,
    .mdc-switch__handle,
    .mdc-switch__handle-track {
      cursor: default;
      pointer-events: none;
    }

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
      .mdc-switch__handle::after {
      background: var(--mdc-switch-disabled-selected-handle-color, #424242);
      opacity: var(--mdc-switch-disabled-handle-opacity, 0.38);
    }

    .mdc-switch:enabled .mdc-switch__track::before {
      background: var(--mdc-switch-disabled-unselected-track-color, #424242);
    }
    .mdc-switch:enabled .mdc-switch__track::after,
    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
      background: var(--mdc-switch-disabled-selected-track-color, #424242);
    }

    .mdc-switch:enabled .mdc-switch__track {
      opacity: var(--mdc-switch-disabled-track-opacity, 0.12);
    }

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icons {
      opacity: var(--mdc-switch-disabled-selected-icon-opacity, 0.38);
    }

    .mdc-switch__ripple {
      display: none;
    }
  }
}

.no-min-width {
  min-width: initial !important;
}

@keyframes mat-mdc-tooltip-show {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
