@use '../variables' as *;

.mat-mdc-dialog-container {
  position: relative;

  h2 {
    font-size: 1rem;
    font-family: $font-family-heading;
    color: var(--theme-light-dark-max);
  }

  p {
    color: var(--theme-light-dark-max);
  }

  .mat-mdc-dialog-title.mdc-dialog__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px; // 40px is the height of the x button
    height: 40px; // 40px is the height of the x button
    padding-left: 24px;
    padding-right: 0;
    padding-bottom: 0;
    background-color: $fundmore-grey-bg;

    h3 {
      font-size: 1rem;
      margin-right: auto;
    }

    + mat-progress-bar {
      right: 0;
      left: 0;
      margin-top: -10px;
    }
  }
}

.mat-dialog-lg {
  .mat-mdc-dialog-container {
    padding: 0;

    .mat-mdc-dialog-title {
      margin: 0;
    }
  }

  .mat-mdc-dialog-content {
    height: 100%;
    max-height: none;
    margin: 0;
    overflow: auto;
  }

  .mdc-dialog .mat-mdc-dialog-content {
    padding: 10px 12px;
  }
}

.mat-mdc-dialog-actions > button {
  min-width: 100px;
}
