.fm-table {
  border-spacing: 0;

  thead tr th,
  tbody tr td {
    padding-right: 8px;
    padding-left: 8px;
  }

  &:not(.mat-mdc-table) {
    // when not a mat-table make the table head height the same as the mat-table
    thead tr {
      height: 48px;

      th {
        text-align: left;
      }
    }

    tbody td {
      height: 52px;
    }
  }

  tbody tr {
    td {
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:last-child td {
      border-bottom: 0;
    }
  }

  .mat-mdc-no-data-row td {
    padding: 8px;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    &.mdc-data-table__cell {
      text-overflow: unset;
    }
  }

  tr {
    .button-open-sidenav {
      background-color: var(--theme-light-max);
      border-radius: 4px;
      line-height: 24px;
      height: 24px;
      width: 24px;
      visibility: hidden;
      padding: unset;
      margin-left: 8px;

      .mat-icon {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &.fm-hover {
    tr {
      &:hover {
        background-color: var(--theme-grey-bg);

        .button-open-sidenav {
          visibility: visible;
        }
      }
    }
  }

  &.mdc-data-table__table {
    min-width: max-content;
    width: 100%;
  }

  .mat-column-options {
    width: 60px;
  }
}

.mat-mdc-paginator-page-size {
  align-items: center;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}
