@use '../variables' as *;

mat-tab-group {
  &.vertical-tabs {
    > mat-tab-header {
      border-bottom: 0;

      .mat-mdc-tab-labels {
        flex-direction: column;

        .mat-mdc-tab {
          justify-content: flex-start;
          border-bottom: 0;

          &.mdc-tab--active {
            background-color: $fundmore-grey-bg;
            border-radius: $border-radius-sm;
          }

          &:not(.mat-mdc-tab-disabled) {
            .mdc-tab__text-label {
              color: var(--theme-light-dark-max);
            }
          }
        }
      }

      .mat-ink-bar {
        display: none;
      }

      .mdc-tab-indicator__content--underline {
        border: none;
      }
    }
  }

  &:not(.vertical-tabs) {
    .mat-mdc-tab-header {
      .mat-mdc-tab {
        font-size: $body-font-size;
      }

      .mat-mdc-tab-label-container {
        border-bottom-color: rgba(0, 0, 0, 0.12);
        border-bottom-style: solid;
      }
    }
  }

  .mat-mdc-tab-body {
    padding: 8px;
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab {
      font-family: $font-family-heading;
      font-size: $body-font-size;
      opacity: 1;
      padding: 0 16px;
      min-width: unset;
      margin-top: 5px;
      height: 35px;

      &.fm-tab-options-button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .mat-mdc-subheader {
    font-size: $small-font-size;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    white-space: nowrap;
    text-align: center;
  }

  &.has-add-button {
    mat-tab-header {
      margin-right: 60px;
    }
  }
}

.fm-tab-add-button {
  position: absolute !important;
  top: 0px;
  right: 10px;
}
